import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public baseUrl = 'https://localhost:44341/';
  //  public baseUrl = 'http://154.61.76.144:92/'
    //public baseUrl = 'https://trendymallorca.in/'
  constructor() {}

  getHeaders() {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append(
      'Authorization',
      'Bearer ' + sessionStorage.getItem('Token')
    );
    return header;
  }
}
