import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";


// import { decode } from "string-encode-decode";
// import { debug } from 'console';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public activeTab = 'home' ;
  constructor(private router: Router ) { }

  ngOnInit(): void {
  
  }
  goToModule(tab:any){
    if(tab == 'win'){
      this.activeTab = tab;
      this.router.navigateByUrl('view/'+ tab);
    } else {
      this.activeTab = tab;
      this.router.navigateByUrl(tab);
    }      
  }

   
}
