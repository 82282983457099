
export const constants: any = {
  paths: {    
    login: 'login', full: "full", blank: "",    
    mine : 'mine' ,
    forgetPassword: "forget-component", reset: "reset-component",    
    resetPassword: "resetpassword", changePassword: "changePassword"
  }
}

export const AlertsData: any = {
  sideOPtions: [
    { name: "Fund", select: false },
    { name: "News", select: false },
    { name: "Marketing", select: false },
    { name: "Channel", select: false },
  ],
  topFunds: [
    { name: "New Top Fund", select: false },
    { name: "EquityFlex Y", select: false },
    { name: "MS INVF US Insight A", select: false },
    { name: "Ninety One American franchise A AccE", select: false },
    { name: "PineBridge US Large Cap RsrchEnh C", select: false },
    { name: "Protea BAM US Equities R USD Acc", select: false },
    { name: "Allianz Invest Nachhaltigkeltsfds T", select: false },
  ],
  myFunds: [
    { name: "Atlantas SICAV Multi World", select: false },
    { name: "BPI Aceos Mundlais FlAA", select: false },
    { name: "BPI GIF Opportunities R", select: false },
    { name: "DPAM INVEST B Equities World Sust B Cap", select: false },
    { name: "Erient hiutabrefasain IV", select: false },
  ],
  MSUnight: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  MyFundsMsUnight: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  category: [
    { name: "General News", select: false },
    { name: "Fund Manager News", select: false },
    { name: "ESG", select: false },
    { name: "Social Media", select: false },
  ],

  marketData: [
    { name: "Marketing", select: false },
    { name: "Surveys", select: false },
    { name: "New Feature", select: false },
    { name: "Other", select: false },
  ],
  ChannelData: [
    { name: "Email", select: false, disable: false },
    // { name: "SMS (Text Message)", select: false },
    { name: "Desktop", select: false, disable: false },
  ],
  marketUniverseData: [
    { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
    { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
    { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
    { name: "EASTERN ASIA", checked: false, isNew: true },
    { name: "EASTERN EUROPE", checked: false, isNew: true },
    { name: "EMERGINGMARKETS", checked: false, isNew: true },
    { name: "ENERGY", checked: false, isNew: true },
    { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
    { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
    { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
    { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
    { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
    { name: "HEALTH CARE", checked: false, isNew: true },
    { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
    { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
    { name: "MATERIALS", checked: false, isNew: true },
    { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
    { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
    { name: "OCEANIA", checked: false, isNew: true },
    { name: "Other", checked: false, isNew: true },
    { name: "SOUTH AMERICA", checked: false, isNew: true },
    { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
    { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
    { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
    { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
    { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
    { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
    { name: "WESTERN EUROPE", checked: false, isNew: true },
  ],
  fundTopFundAlerts: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  fundMFundAlerts: [
    { name: "General News", select: false },
    { name: "Fund Manager News", select: false },
    { name: "ESG", select: false },
    { name: "Social Media", select: false },
  ],
  newsTopFundAlerts: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  newsMyFundAlerts: [
    { name: "General News", select: false },
    { name: "Fund Manager News", select: false },
    { name: "ESG", select: false },
    { name: "Social Media", select: false },
  ],
}
export class MsGrowthViewChart {
  GLOBAL_GROWTH = {
    DatesForXaxes: [
      "4/30/2014",
      "5/31/2014",
      "6/30/2014",
      "7/31/2014",
      "8/31/2014",
      "9/30/2014",
      "10/31/2014",
      "11/30/2014",
      "12/31/2014",
      "1/31/2015",
      "2/28/2015",
      "3/31/2015",
      "4/30/2015",
      "5/31/2015",
      "6/30/2015",
      "7/31/2015",
      "8/31/2015",
      "9/30/2015",
      "10/31/2015",
      "11/30/2015",
      "12/31/2015",
      "1/31/2016",
      "2/29/2016",
      "3/31/2016",
      "4/30/2016",
      "5/31/2016",
      "6/30/2016",
      "7/31/2016",
      "8/31/2016",
      "9/30/2016",
      "10/31/2016",
      "11/30/2016",
      "12/31/2016",
      "1/31/2017",
      "2/28/2017",
      "3/31/2017",
      "4/30/2017",
      "5/31/2017",
      "6/30/2017",
      "7/31/2017",
      "8/31/2017",
      "9/30/2017",
      "10/31/2017",
      "11/30/2017",
      "12/31/2017",
      "1/31/2018",
      "2/28/2018",
      "3/31/2018",
      "4/30/2018",
      "5/31/2018",
      "6/30/2018",
      "7/31/2018",
      "8/31/2018",
      "9/30/2018",
      "10/31/2018",
      "11/30/2018",
      "12/31/2018",
      "1/31/2019",
      "2/28/2019",
      "3/31/2019",
      "4/30/2019",
      "5/31/2019",
      "6/30/2019",
      "7/31/2019",
      "8/31/2019",
      "9/30/2019",
      "10/31/2019",
      "11/30/2019",
      "12/31/2019",
      "1/31/2020",
      "2/29/2020",
      "3/31/2020",
      "4/30/2020",
    ],

    title: "Global Top Funds Growth",

    DatesForYaxes: [
      {
        name: "Echiquier World Next Leaders A",
        color: "#AB036E",

        data: [
          100.0,
          101.8,
          103.8,
          105.3,
          107.2,
          107.1,
          112.6,
          114.5,
          114.8,
          120.9,
          130.1,
          132.6,
          130.2,
          134.7,
          133.4,
          135.3,
          124.4,
          122.6,
          132.3,
          137.6,
          133.0,
          120.9,
          121.3,
          123.8,
          127.0,
          129.4,
          126.4,
          134.6,
          132.4,
          134.1,
          133.1,
          135.6,
          135.7,
          140.4,
          146.3,
          148.1,
          150.9,
          153.6,
          150.3,
          150.0,
          147.8,
          152.0,
          154.9,
          156.4,
          155.0,
          163.9,
          159.9,
          156.2,
          158.3,
          167.1,
          163.0,
          168.2,
          176.4,
          177.1,
          149.9,
          161.3,
          148.2,
          166.9,
          177.8,
          187.6,
          192.1,
          180.5,
          187.9,
          201.3,
          204.1,
          191.4,
          192.9,
          205.0,
          203.9,
          218.4,
          216.9,
          192.7,
          234.2,
        ],
      },

      {
        name: "Fundsmith Equity R Acc",
        color: "#C71D39",
        data: [
          100.0,
          104.0,
          102.7,
          103.6,
          106.5,
          108.1,
          113.3,
          120.7,
          121.5,
          126.5,
          127.3,
          128.4,
          127.2,
          130.7,
          122.3,
          129.4,
          124.7,
          124.0,
          133.7,
          136.7,
          140.0,
          138.6,
          146.4,
          149.3,
          147.5,
          150.1,
          162.5,
          172.0,
          174.0,
          175.2,
          179.5,
          173.7,
          178.5,
          179.6,
          189.1,
          193.2,
          194.1,
          204.2,
          201.9,
          203.8,
          208.1,
          201.6,
          213.2,
          213.5,
          216.6,
          220.6,
          216.0,
          206.2,
          213.4,
          226.4,
          231.2,
          236.9,
          244.1,
          243.9,
          229.2,
          236.6,
          220.3,
          229.5,
          239.6,
          252.8,
          259.2,
          262.3,
          272.2,
          287.1,
          285.2,
          275.9,
          268.9,
          274.8,
          275.3,
          282.6,
          263.1,
          253.3,
          274.8,
        ],
      },

      {
        name: "Fundsmith Equity Sicav R EUR Acc",
        color: "#FF5734",
        data: [
          100.0,
          105.1,
          105.4,
          107.3,
          110.0,
          114.0,
          118.2,
          124.6,
          128.0,
          137.9,
          143.5,
          145.1,
          143.7,
          149.0,
          140.8,
          150.2,
          139.2,
          137.5,
          152.4,
          158.7,
          155.7,
          148.4,
          152.2,
          154.4,
          154.5,
          160.7,
          160.5,
          166.5,
          167.4,
          166.0,
          162.7,
          165.7,
          169.5,
          169.6,
          180.8,
          183.5,
          187.1,
          190.4,
          187.1,
          185.6,
          183.9,
          185.9,
          197.0,
          197.3,
          198.6,
          204.0,
          198.9,
          191.3,
          196.9,
          210.0,
          212.0,
          215.9,
          221.0,
          223.0,
          209.5,
          215.5,
          199.4,
          212.9,
          226.6,
          239.3,
          244.0,
          240.3,
          246.2,
          253.4,
          254.6,
          252.0,
          253.8,
          261.4,
          262.8,
          271.8,
          250.4,
          232.2,
          255.5,
        ],
      },

      {
        name: "GMO Quality Investment USD Acc",
        color: "#FFC302",
        data: [
          100.0,
          101.9,
          102.3,
          101.3,
          104.7,
          103.4,
          105.8,
          109.8,
          108.6,
          105.9,
          111.3,
          108.8,
          109.4,
          110.1,
          107.0,
          110.5,
          104.7,
          102.5,
          110.5,
          109.7,
          109.7,
          107.2,
          107.5,
          113.9,
          112.7,
          115.5,
          116.5,
          120.8,
          120.5,
          120.7,
          118.0,
          117.9,
          119.6,
          121.7,
          128.4,
          130.2,
          133.4,
          138.0,
          138.4,
          139.8,
          141.7,
          142.7,
          148.7,
          152.5,
          153.1,
          162.3,
          156.9,
          151.6,
          152.9,
          155.9,
          157.6,
          165.9,
          172.2,
          173.9,
          164.6,
          167.1,
          153.1,
          162.3,
          167.1,
          172.8,
          177.6,
          168.7,
          179.0,
          183.1,
          179.8,
          181.6,
          187.3,
          193.8,
          199.7,
          198.6,
          183.5,
          166.6,
          184.9,
        ],
      },

      {
        name: "Lindsell Train Global Equity A GBP Inc",
        color: "#99FF66",
        data: [
          100.0,
          103.4,
          103.1,
          103.5,
          106.9,
          107.2,
          107.7,
          113.4,
          111.8,
          118.8,
          122.6,
          130.2,
          128.1,
          130.7,
          127.2,
          133.4,
          127.7,
          125.5,
          132.8,
          134.2,
          133.0,
          130.9,
          137.7,
          139.0,
          138.0,
          139.7,
          149.3,
          161.1,
          161.0,
          164.9,
          168.7,
          162.2,
          163.8,
          164.1,
          171.4,
          175.3,
          177.2,
          191.3,
          188.8,
          192.1,
          198.0,
          191.8,
          198.4,
          203.2,
          205.5,
          207.1,
          209.1,
          206.2,
          213.0,
          229.0,
          232.6,
          236.2,
          245.5,
          246.8,
          228.6,
          236.8,
          227.1,
          234.0,
          239.3,
          251.5,
          263.9,
          268.8,
          274.5,
          287.6,
          290.0,
          281.6,
          268.2,
          270.2,
          269.8,
          269.3,
          248.3,
          239.9,
          258.0,
        ],
      },

      {
        name: "MFG Global 1 USD Acc",
        color: "#66CCCC",
        data: [
          100.0,
          101.6,
          102.2,
          100.4,
          102.0,
          100.7,
          100.4,
          105.5,
          105.3,
          103.0,
          109.3,
          107.4,
          109.8,
          111.1,
          108.6,
          112.3,
          107.0,
          104.1,
          110.3,
          110.2,
          108.5,
          104.0,
          103.4,
          109.3,
          107.6,
          108.9,
          105.7,
          111.0,
          111.6,
          112.0,
          110.4,
          110.9,
          112.2,
          114.6,
          118.6,
          119.9,
          123.2,
          126.8,
          125.9,
          128.3,
          129.9,
          130.8,
          134.3,
          137.5,
          139.0,
          146.6,
          141.8,
          137.3,
          137.5,
          141.8,
          142.5,
          146.3,
          151.7,
          152.4,
          145.8,
          146.7,
          138.0,
          146.6,
          148.4,
          153.7,
          160.0,
          153.3,
          163.2,
          166.7,
          167.1,
          164.7,
          167.3,
          171.9,
          177.1,
          180.2,
          167.7,
          152.4,
          166.0,
        ],
      },

      {
        name: "Mirabaud Equities Global Focus A USD Acc",
        color: "#1F639B",
        data: [
          100.0,
          103.3,
          106.7,
          106.1,
          108.4,
          104.6,
          104.9,
          105.1,
          102.3,
          100.2,
          105.6,
          105.0,
          105.7,
          104.6,
          103.1,
          106.9,
          100.4,
          97.0,
          104.2,
          104.0,
          101.7,
          95.2,
          91.1,
          95.3,
          95.1,
          96.5,
          95.4,
          97.7,
          96.6,
          97.8,
          94.9,
          95.5,
          94.8,
          98.3,
          99.9,
          100.9,
          103.6,
          105.7,
          105.5,
          108.3,
          110.6,
          113.5,
          113.6,
          117.8,
          120.0,
          128.3,
          127.4,
          126.7,
          124.8,
          127.0,
          128.3,
          131.8,
          134.0,
          136.4,
          126.7,
          131.0,
          123.1,
          131.7,
          136.3,
          141.2,
          145.6,
          142.2,
          150.4,
          151.7,
          148.3,
          147.8,
          147.8,
          150.8,
          156.9,
          155.7,
          145.2,
          126.4,
          137.3,
        ],
      },
    ],
  };

  EMERGING_MARKETS = {
    DatesForXaxes: [
      "4/30/2014",
      "5/31/2014",
      "6/30/2014",
      "7/31/2014",
      "8/31/2014",
      "9/30/2014",
      "10/31/2014",
      "11/30/2014",
      "12/31/2014",
      "1/31/2015",
      "2/28/2015",
      "3/31/2015",
      "4/30/2015",
      "5/31/2015",
      "6/30/2015",
      "7/31/2015",
      "8/31/2015",
      "9/30/2015",
      "10/31/2015",
      "11/30/2015",
      "12/31/2015",
      "1/31/2016",
      "2/29/2016",
      "3/31/2016",
      "4/30/2016",
      "5/31/2016",
      "6/30/2016",
      "7/31/2016",
      "8/31/2016",
      "9/30/2016",
      "10/31/2016",
      "11/30/2016",
      "12/31/2016",
      "1/31/2017",
      "2/28/2017",
      "3/31/2017",
      "4/30/2017",
      "5/31/2017",
      "6/30/2017",
      "7/31/2017",
      "8/31/2017",
      "9/30/2017",
      "10/31/2017",
      "11/30/2017",
      "12/31/2017",
      "1/31/2018",
      "2/28/2018",
      "3/31/2018",
      "4/30/2018",
      "5/31/2018",
      "6/30/2018",
      "7/31/2018",
      "8/31/2018",
      "9/30/2018",
      "10/31/2018",
      "11/30/2018",
      "12/31/2018",
      "1/31/2019",
      "2/28/2019",
      "3/31/2019",
      "4/30/2019",
      "5/31/2019",
      "6/30/2019",
      "7/31/2019",
      "8/31/2019",
      "9/30/2019",
      "10/31/2019",
      "11/30/2019",
      "12/31/2019",
      "1/31/2020",
      "2/29/2020",
      "3/31/2020",
      "4/30/2020",
    ],

    title: "Emerging Market Top Funds Growth",

    DatesForYaxes: [
      {
        name: "Baillie Gifford Em Mkts Lead Coms A Acc",
        color: "#AB036E",
        data: [
          100.0,
          106.0,
          107.4,
          109.9,
          114.1,
          109.8,
          111.8,
          113.6,
          107.0,
          114.6,
          113.8,
          114.9,
          114.8,
          113.3,
          106.8,
          101.1,
          92.7,
          92.0,
          99.3,
          102.3,
          100.8,
          94.7,
          95.9,
          104.4,
          102.0,
          100.7,
          110.6,
          119.8,
          126.9,
          130.8,
          138.5,
          128.3,
          128.4,
          135.0,
          141.3,
          146.9,
          145.6,
          155.8,
          154.9,
          167.0,
          170.3,
          163.5,
          173.2,
          173.0,
          177.0,
          184.0,
          178.0,
          169.1,
          167.9,
          168.1,
          165.7,
          170.9,
          165.7,
          167.1,
          153.9,
          161.9,
          156.1,
          167.2,
          166.7,
          175.1,
          181.5,
          173.1,
          185.2,
          193.3,
          182.4,
          184.6,
          183.8,
          185.9,
          195.9,
          193.2,
          182.1,
          160.3,
          176.5,
        ],
      },

      {
        name: "Baillie Gifford Emerging Mkts Gr A Acc",
        color: "#C71D39",
        data: [
          100.0,
          106.0,
          108.0,
          108.2,
          115.0,
          110.1,
          110.3,
          113.1,
          108.9,
          115.1,
          114.7,
          116.7,
          117.7,
          117.1,
          109.8,
          102.9,
          94.3,
          94.2,
          101.3,
          103.8,
          104.5,
          96.0,
          97.3,
          104.3,
          101.0,
          100.5,
          109.0,
          118.7,
          127.0,
          129.9,
          136.6,
          129.1,
          128.0,
          134.8,
          139.6,
          146.5,
          144.4,
          153.0,
          152.6,
          164.5,
          171.5,
          164.8,
          175.0,
          174.4,
          177.5,
          185.0,
          178.4,
          170.3,
          169.3,
          169.6,
          166.2,
          171.3,
          165.5,
          169.0,
          157.0,
          164.6,
          158.1,
          169.0,
          168.9,
          176.4,
          182.5,
          174.0,
          185.9,
          191.9,
          181.0,
          184.4,
          183.2,
          184.1,
          194.1,
          189.0,
          176.6,
          153.6,
          169.5,
        ],
      },

      {
        name: "Gudme Raaschou EM Aktier",
        color: "#FF5734",
        data: [
          100.0,
          106.1,
          107.5,
          112.4,
          115.6,
          110.9,
          115.2,
          117.1,
          114.6,
          123.9,
          127.7,
          131.4,
          133.9,
          130.4,
          125.1,
          116.8,
          104.6,
          102.3,
          112.4,
          114.1,
          107.1,
          99.7,
          98.0,
          104.5,
          104.4,
          104.8,
          108.4,
          114.3,
          116.8,
          118.0,
          120.1,
          119.6,
          120.0,
          123.7,
          129.0,
          131.5,
          132.6,
          133.0,
          133.9,
          139.0,
          143.3,
          143.2,
          150.7,
          149.7,
          152.4,
          159.8,
          154.5,
          149.7,
          150.5,
          150.6,
          145.8,
          148.0,
          144.2,
          144.8,
          137.9,
          143.4,
          138.2,
          150.5,
          153.5,
          156.9,
          162.2,
          151.5,
          158.0,
          160.3,
          155.2,
          159.2,
          161.7,
          164.4,
          173.4,
          168.8,
          158.7,
          134.9,
          146.1,
        ],
      },

      {
        name: "JPM Emerging Markets A Net Acc",
        color: "#FFC302",
        data: [
          100.0,
          104.1,
          103.7,
          106.8,
          108.7,
          103.9,
          108.6,
          111.1,
          104.7,
          111.2,
          111.1,
          112.2,
          110.4,
          107.7,
          102.2,
          99.2,
          92.1,
          89.0,
          95.0,
          95.2,
          92.6,
          90.1,
          92.4,
          101.0,
          101.2,
          99.0,
          111.1,
          121.5,
          123.6,
          127.1,
          134.7,
          123.8,
          126.1,
          130.6,
          134.7,
          139.8,
          139.4,
          145.5,
          145.4,
          151.9,
          158.0,
          151.9,
          156.8,
          157.9,
          162.8,
          167.6,
          163.0,
          154.9,
          156.8,
          158.1,
          154.6,
          157.7,
          152.8,
          150.5,
          138.4,
          148.8,
          143.4,
          152.8,
          154.9,
          161.3,
          168.8,
          164.1,
          174.5,
          182.2,
          174.4,
          175.4,
          175.4,
          176.9,
          182.0,
          177.9,
          170.9,
          150.0,
          162.8,
        ],
      },
    ],
  };

  EUROPE_GROWTH = {
    DatesForXaxes: [
      "4/30/2014",
      "5/31/2014",
      "6/30/2014",
      "7/31/2014",
      "8/31/2014",
      "9/30/2014",
      "10/31/2014",
      "11/30/2014",
      "12/31/2014",
      "1/31/2015",
      "2/28/2015",
      "3/31/2015",
      "4/30/2015",
      "5/31/2015",
      "6/30/2015",
      "7/31/2015",
      "8/31/2015",
      "9/30/2015",
      "10/31/2015",
      "11/30/2015",
      "12/31/2015",
      "1/31/2016",
      "2/29/2016",
      "3/31/2016",
      "4/30/2016",
      "5/31/2016",
      "6/30/2016",
      "7/31/2016",
      "8/31/2016",
      "9/30/2016",
      "10/31/2016",
      "11/30/2016",
      "12/31/2016",
      "1/31/2017",
      "2/28/2017",
      "3/31/2017",
      "4/30/2017",
      "5/31/2017",
      "6/30/2017",
      "7/31/2017",
      "8/31/2017",
      "9/30/2017",
      "10/31/2017",
      "11/30/2017",
      "12/31/2017",
      "1/31/2018",
      "2/28/2018",
      "3/31/2018",
      "4/30/2018",
      "5/31/2018",
      "6/30/2018",
      "7/31/2018",
      "8/31/2018",
      "9/30/2018",
      "10/31/2018",
      "11/30/2018",
      "12/31/2018",
      "1/31/2019",
      "2/28/2019",
      "3/31/2019",
      "4/30/2019",
      "5/31/2019",
      "6/30/2019",
      "7/31/2019",
      "8/31/2019",
      "9/30/2019",
      "10/31/2019",
      "11/30/2019",
      "12/31/2019",
      "1/31/2020",
      "2/29/2020",
      "3/26/2020",
      "3/31/2020",
      "4/30/2020",
    ],

    title: "Europe Top Funds Growth",

    DatesForYaxes: [
      {
        name: "G Fund Future For Generations N",
        color: "#AB036E",
        data: [
          100.0,
          101.9,
          101.7,
          99.3,
          101.2,
          101.2,
          99.0,
          102.1,
          100.1,
          107.1,
          111.9,
          114.7,
          114.0,
          116.5,
          111.2,
          117.7,
          110.2,
          107.3,
          114.9,
          118.3,
          114.2,
          107.7,
          105.8,
          106.8,
          108.0,
          111.9,
          111.7,
          115.3,
          114.8,
          115.9,
          113.5,
          112.5,
          117.8,
          119.1,
          122.5,
          124.4,
          128.8,
          130.3,
          126.5,
          126.6,
          125.6,
          128.7,
          132.5,
          129.2,
          130.4,
          132.2,
          126.1,
          123.4,
          132.1,
          134.6,
          134.5,
          146.5,
          145.0,
          145.1,
          137.9,
          136.4,
          129.1,
          137.9,
          142.3,
          146.5,
          150.6,
          146.0,
          151.7,
          152.7,
          152.8,
          156.2,
          154.6,
          156.2,
          156.9,
          157.9,
          155.6,
          143.8,
          150.0,
        ],
      },

      {
        name: "Fidelity FAST Europe A-ACC-EUR",
        color: "#C71D39",
        data: [
          100.0,
          102.7,
          102.2,
          100.4,
          103.4,
          103.9,
          102.1,
          105.6,
          104.5,
          111.5,
          120.0,
          124.4,
          122.4,
          125.7,
          121.4,
          125.3,
          115.5,
          110.4,
          117.4,
          120.3,
          114.8,
          106.6,
          104.2,
          101.1,
          102.5,
          107.5,
          100.3,
          103.3,
          102.9,
          100.5,
          100.2,
          102.2,
          108.5,
          106.2,
          109.0,
          111.0,
          112.0,
          114.3,
          110.6,
          108.9,
          108.8,
          112.5,
          115.6,
          113.6,
          114.9,
          116.0,
          112.9,
          112.4,
          117.6,
          123.1,
          125.3,
          127.7,
          128.8,
          129.0,
          119.5,
          115.8,
          110.5,
          117.6,
          125.9,
          134.5,
          139.3,
          136.3,
          142.7,
          146.3,
          146.6,
          146.0,
          149.2,
          156.6,
          159.5,
          163.8,
          157.3,
          140.4,
          150.6,
        ],
      },

      {
        name: "Fidelity Instl Eurp LargerComs I-Acc-EUR",
        color: "#FF5734",
        data: [
          100.0,
          103.3,
          103.2,
          101.9,
          104.3,
          104.6,
          103.0,
          106.9,
          105.8,
          113.8,
          122.8,
          125.5,
          125.9,
          127.6,
          121.4,
          126.6,
          115.5,
          110.1,
          119.1,
          121.9,
          115.9,
          109.5,
          107.6,
          108.7,
          111.8,
          115.3,
          110.7,
          114.1,
          114.8,
          115.0,
          114.0,
          118.1,
          124.4,
          123.2,
          126.9,
          130.6,
          132.9,
          135.3,
          130.4,
          128.3,
          125.7,
          132.1,
          135.3,
          133.1,
          134.0,
          134.8,
          129.3,
          127.3,
          134.6,
          138.2,
          138.8,
          140.8,
          140.0,
          140.3,
          131.9,
          130.2,
          122.4,
          130.9,
          137.0,
          139.8,
          145.4,
          136.6,
          143.1,
          141.0,
          135.0,
          139.8,
          141.5,
          146.8,
          150.7,
          149.3,
          136.3,
          113.9,
        ],
      },

      {
        name: "Groupama Europe Stock N",
        color: "#FFC302",
        data: [
          100.0,
          101.9,
          101.8,
          99.4,
          101.3,
          101.4,
          99.2,
          102.3,
          100.4,
          107.4,
          112.3,
          114.3,
          113.7,
          116.3,
          111.0,
          117.6,
          110.0,
          107.2,
          114.9,
          118.3,
          114.2,
          107.7,
          105.9,
          107.0,
          108.2,
          112.2,
          112.1,
          115.7,
          115.2,
          116.5,
          114.0,
          113.1,
          118.4,
          119.8,
          123.4,
          125.4,
          130.0,
          131.7,
          127.7,
          127.9,
          127.0,
          130.2,
          134.2,
          130.9,
          132.2,
          134.0,
          127.8,
          125.0,
          134.1,
          136.7,
          136.7,
          149.3,
          147.7,
          148.0,
          140.5,
          139.0,
          131.5,
          140.6,
          145.2,
          149.6,
          153.9,
          149.2,
          155.2,
          156.3,
          156.4,
          160.1,
          159.6,
          163.4,
          165.5,
          166.8,
          155.0,
          137.5,
          145.3,
        ],
      },

      {
        name: "MFS Meridian European Value B1 USD",
        color: "#99FF66",
        data: [
          100.0,
          101.5,
          101.2,
          97.9,
          98.9,
          95.5,
          95.4,
          98.3,
          95.5,
          97.0,
          100.9,
          98.3,
          101.9,
          101.6,
          97.5,
          101.8,
          97.2,
          94.7,
          99.9,
          99.6,
          98.6,
          94.5,
          93.2,
          99.7,
          99.7,
          100.3,
          98.3,
          102.3,
          103.1,
          103.1,
          96.6,
          92.5,
          93.6,
          96.6,
          98.2,
          101.5,
          106.5,
          112.2,
          111.0,
          112.4,
          112.4,
          114.8,
          117.9,
          120.2,
          122.4,
          126.4,
          120.0,
          120.7,
          120.2,
          121.4,
          121.1,
          124.4,
          125.4,
          123.1,
          113.8,
          114.1,
          108.9,
          114.3,
          118.4,
          121.4,
          125.6,
          122.8,
          127.4,
          124.8,
          124.0,
          124.7,
          128.6,
          130.8,
          135.5,
          134.6,
          125.2,
          108.5,
          115.3,
        ],
      },
    ],
  };

  US_MARKET = {
    DatesForXaxes: [
      "4/30/2014",
      "5/31/2014",
      "6/30/2014",
      "7/31/2014",
      "8/31/2014",
      "9/30/2014",
      "10/31/2014",
      "11/30/2014",
      "12/31/2014",
      "1/31/2015",
      "2/28/2015",
      "3/31/2015",
      "4/30/2015",
      "5/31/2015",
      "6/30/2015",
      "7/31/2015",
      "8/31/2015",
      "9/30/2015",
      "10/31/2015",
      "11/30/2015",
      "12/31/2015",
      "1/31/2016",
      "2/29/2016",
      "3/31/2016",
      "4/30/2016",
      "5/31/2016",
      "6/30/2016",
      "7/31/2016",
      "8/31/2016",
      "9/30/2016",
      "10/31/2016",
      "11/30/2016",
      "12/31/2016",
      "1/31/2017",
      "2/28/2017",
      "3/31/2017",
      "4/30/2017",
      "5/31/2017",
      "6/30/2017",
      "7/31/2017",
      "8/31/2017",
      "9/30/2017",
      "10/31/2017",
      "11/30/2017",
      "12/31/2017",
      "1/31/2018",
      "2/28/2018",
      "3/31/2018",
      "4/30/2018",
      "5/31/2018",
      "6/30/2018",
      "7/31/2018",
      "8/31/2018",
      "9/30/2018",
      "10/31/2018",
      "11/30/2018",
      "12/31/2018",
      "1/31/2019",
      "2/28/2019",
      "3/31/2019",
      "4/30/2019",
      "5/31/2019",
      "6/30/2019",
      "7/31/2019",
      "8/31/2019",
      "9/30/2019",
      "10/31/2019",
      "11/30/2019",
      "12/31/2019",
      "1/31/2020",
      "2/29/2020",
      "3/31/2020",
      "4/30/2020",
    ],

    title: "US Top Funds Growth",
    DatesForYaxes: [
      {
        name: "AXA Framlington US Select Grw Fd A-D USD",
        color: "#AB036E",
        data: [
          100.0,
          103.4,
          105.5,
          104.3,
          108.4,
          106.9,
          109.7,
          111.9,
          111.2,
          108.8,
          115.6,
          114.8,
          114.7,
          116.2,
          114.9,
          118.7,
          111.1,
          108.5,
          117.3,
          117.4,
          115.5,
          106.9,
          106.3,
          113.6,
          113.9,
          116.0,
          114.4,
          119.2,
          119.7,
          119.6,
          117.0,
          120.2,
          121.6,
          125.1,
          130.0,
          130.2,
          132.7,
          134.0,
          134.5,
          138.0,
          138.0,
          140.3,
          143.5,
          148.5,
          150.6,
          160.0,
          155.0,
          151.4,
          152.2,
          156.1,
          157.0,
          163.2,
          169.4,
          170.5,
          158.0,
          160.3,
          142.9,
          156.8,
          161.4,
          165.4,
          175.5,
          164.3,
          176.3,
          179.6,
          176.4,
          177.3,
          181.1,
          188.7,
          195.2,
          195.0,
          179.2,
          155.4,
          176.0,
        ],
      },

      {
        name: "Ninety One American Franchise A Acc GBP (was Investec)",
        color: "#C71D39",
        data: [
          100.0,
          101.7,
          101.8,
          101.9,
          105.3,
          106.7,
          108.3,
          115.0,
          117.3,
          119.0,
          121.2,
          125.7,
          121.8,
          123.2,
          117.8,
          117.4,
          111.8,
          109.9,
          116.1,
          119.3,
          118.6,
          112.8,
          121.3,
          124.0,
          125.1,
          125.2,
          132.6,
          142.7,
          145.2,
          143.9,
          153.8,
          163.1,
          170.0,
          169.0,
          175.6,
          176.3,
          171.1,
          170.9,
          176.5,
          178.4,
          180.1,
          174.6,
          183.7,
          183.2,
          185.3,
          186.7,
          189.0,
          180.3,
          188.5,
          199.3,
          205.2,
          208.8,
          217.5,
          218.0,
          206.0,
          212.0,
          198.5,
          206.2,
          214.4,
          221.5,
          230.6,
          230.7,
          238.7,
          258.0,
          258.4,
          250.4,
          243.7,
          251.0,
          250.6,
          263.5,
          243.3,
          234.1,
          258.7,
        ],
      },

      {
        name: "Investec GSF American Fran C Inc USD",
        color: "#FF5734",
        data: [
          100.0,
          100.7,
          102.2,
          100.4,
          103.3,
          101.2,
          101.6,
          105.6,
          106.0,
          103.6,
          108.6,
          108.1,
          108.1,
          108.5,
          106.6,
          105.8,
          100.4,
          96.4,
          102.4,
          102.7,
          100.2,
          95.0,
          95.9,
          102.4,
          104.2,
          104.7,
          103.7,
          107.5,
          108.5,
          107.6,
          106.2,
          115.4,
          118.4,
          119.0,
          123.0,
          123.6,
          124.4,
          123.3,
          128.9,
          131.2,
          131.1,
          131.6,
          136.3,
          138.6,
          140.0,
          148.5,
          145.7,
          143.5,
          143.8,
          147.7,
          149.8,
          152.9,
          157.0,
          157.3,
          146.9,
          150.5,
          139.2,
          149.8,
          156.3,
          160.6,
          164.9,
          159.3,
          167.3,
          170.5,
          171.8,
          169.7,
          172.9,
          176.8,
          181.1,
          185.4,
          173.1,
          156.0,
          174.1,
        ],
      },
    ],
  };
}

export const ProfileData: any = {
  sideOptions: [
    { name: "User", select: false },
    { name: "Security", select: false },
    { name: "Organisation", select: false }
  ],
  userOptions: [
    { value: "", placeholder: "Username", name: "userName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "First Name", name: "firstName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Surname", name: "surName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Email Primary", name: "emailPrimary", select: false, errMsg: "", type: "email" },
    { value: "", placeholder: "Email Secondary", name: "emailSecondary", select: false, errMsg: "", type: "email" },
    { value: "", placeholder: "Mobile", name: "mobile", select: false, errMsg: "", type: "text" },
  ],
  security: [
    { value: "", placeholder: "Verify current password", name: "currentPassword", select: false, errMsg: "", type: "password" },
    { value: "", placeholder: "New password", name: "newPassword", select: false, errMsg: "", type: "password" },
    { value: "", placeholder: "Confirm new password", name: "confirmNewPassword", select: false, errMsg: "", type: "password" },
  ],
  organization: [
    { value: "", placeholder: "Company Name", name: "companyName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Role", name: "role", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Location", name: "location", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Investor Type", name: "investorType", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Investment Preferences", name: "investmentPreference", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Investment Style (Risk Appetite)", name: "investmentStyle", select: false, errMsg: "", type: "text" },
  ]
}


export const MarketNews: any = {
  title: "Market Insights",
  placeHolder: "Search Funds",
  filterTitle: "News Type - Filter",
  filterItems: [
    { name: "FUNDSaiQ Curated", select: false, disable: false },
    { name: "General Fund News", select: false, disable: false },
    { name: "Social Media", select: false, disable: true }
  ],
  newsTableHeadings: [
    { name: "Date" },
    { name: "News Type" },
    { name: "Headline" },
    { name: "Abstract" },
    { name: "Author" },
    { name: "Source" },
  ],
  marketNewsData: [
    { name: "FUNDSaiQ Curated", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 1, showDatePicker: false },
    { name: "Social Media", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 2, showDatePicker: false },
    { name: "General News", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 3, showDatePicker: false },
    { name: "FUNDSaiQ Curated", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 4, showDatePicker: false },
    { name: "Social Media", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 5, showDatePicker: false },
  ]
}

export const AlertsAndNews: any = {
  title: "Alerts",
  placeHolder: "Search Alerts",
  filterTitle: "Alert Type Filter",
  filterItems: [
    { name: "Manager Change", select: false },
    { name: "Fund Size Change", select: false },
    { name: "Fund Closure", select: false }
  ],
  newsTableHeadings: [
    { name: "Date" },
    { name: "Alert Type" },
    { name: "Alert Details" },
    { name: "Fund Name" },
    { name: "Category" },
    // { name: "Insight" },
  ],
  alertNewsFeed: [
    { name: "Manager Change", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 1, showDatePicker: false },
    { name: "Fund Closure", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 2, showDatePicker: false },
    { name: "Fund Size Change", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 3, showDatePicker: false },
    { name: "Manager Change", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 4, showDatePicker: false },
    { name: "Fund Closure", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 5, showDatePicker: false },
  ]
}

export const Preference: any = {
  prefrenceOptions: [
    {
      name: "FUND SETTINGS",
      option: [{ optName: "Assets Class", select: false },
      { optName: "  categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Jan', 'Feb', 'Mar', 'Apr', 'Jan', 'Feb', 'Mar', 'Apr',],", select: false },
      {
        optName: "Geography of Sale",
        select: false,
        subcategory: [{ name: "Country of Sales" },
        { name: "Investment Area" }, { name: "Region of Sales" }]
      }, { optName: "Display Currency", select: false }, { optName: "ESG", select: false }, { name: "GBP", checked: false }],
      select: false
    },
    {
      name: "MY FUNDS", option: [{ optName: "Funds", select: false },
      { optName: "Rebates", select: false }], select: false
    },
  ],
  regionData: [
    { name: "Europe", select: false },
    { name: "EMEA", select: false },
    { name: "North America", select: false },
    { name: "Asia", select: false },
    { name: "Emerging Markets", select: false },
  ],
  assetClassData: [
    { name: "Equity", select: true },
    { name: "Fixed Income", select: false }
  ],
  checkboxData: [
    { head1: "Asia", checked: false, image: "../../../assets/images/expandb.svg", img1: "../../../assets/images/expandActiv.svg" },
    { name1: "Japan", checked: false },
    { name1: "Hong Kong", checked: false },
    { name1: "Singapore", checked: false },
    { head2: "Europe", checked: false, image: "../../../assets/images/expandb.svg", img2: "../../../assets/images/expandActiv.svg" },
    { name2: "Russia", checked: false },
    { name2: "Germany", checked: false },
    { head: "EMEA", checked: false, image: "../../../assets/images/expandb.svg", img: "../../../assets/images/expandActiv.svg" },
    { head3: "North America", checked: false, image: "../../../assets/images/expandb.svg", img3: "../../../assets/images/expandActiv.svg" },
    { name3: "Canada", checked: false },
    { name3: "Greenland", checked: false },
    { head: "Emerging Markets", checked: false, image: "../../../assets/images/expandb.svg", img: "../../../assets/images/expandActiv.svg" },
  ],
  countryCheckboxData: [
    { name: "France", checked: false },
    { name: "Hong Kong", checked: false },
    { name: "Japan", checked: false },
    { name: "Singapore", checked: false },
    { name: "United Arab Emirates", checked: false },
    { name: "United kingdom of Great Britain", checked: false },
    { name: "United States of America", checked: false },
  ],
  esgOptionsInclude: [
    { name: "Alternative Energy ", checked: false },
    { name: "Energy Efficient ", checked: false },
    // { name: "Pollution Prevention ", checked: false },
    { name: "Social Impact Companies ", checked: false },
    { name: "Board Independence ", checked: false },
  ],
  esgOptionExclude: [
    { name: "Severe Labor Controversies ", checked: false },
    { name: "Severe Human Rights and Community Controversies ", checked: false },
    { name: "UN Global Compact Compliance Violation ", checked: false },
    { name: "Human Rights Norms Violation ", checked: false },
    { name: "Adult Entertainment ", checked: false },
    { name: "Alcohol ", checked: false },
    { name: "Gambling ", checked: false },
    { name: "Genetic Engineering - GMO ", checked: false },
    { name: "Nuclear Power ", checked: false },
    { name: "Predatory Lending ", checked: false },
    { name: "Tobacco ", checked: false },
    { name: "Controversial Weapons ", checked: false },
    { name: "Weapons", checked: false },
    { name: "Fossil Fuel Reserves", checked: false },
    { name: "No Female Directors ", checked: false },
    { name: "Carbon Emissions", checked: false },
  ],
  esgOptionsIncludeChart: [
    { name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
    { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
    // { name: "Pollution Prevention ", checked: false },
    { name: "Social Impact Companies ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
    { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" },
  ],
  esgOptionExcludeChart: [
    { name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
    { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
    { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
    { name: "Human Rights Norms Violation ", checked: false, value: "HR_COMPLIANCE" },
    { name: "Adult Entertainment ", checked: false, value: "AE_TIE" },
    { name: "Alcohol ", checked: false, value: "ALC_TIE" },
    { name: "Gambling", checked: false, value: "GAM_TIE" },
    { name: "Genetic Engineering - GMO ", checked: false, value: "GMO_TIE" },
    { name: "Nuclear Power ", checked: false, value: "NUC_TIE" },
    { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
    { name: "Tobacco ", checked: false, value: "TOB_TIE" },
    { name: "Controversial Weapons ", checked: false, value: "CWEAP_TIE" },
    { name: "Weapons", checked: false, value: "WEAP_GTIE" },
    { name: "Fossil Fuel Reserves", checked: false, value: "FOSSIL_FUEL_RESERVES" },
    { name: "No Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
    { name: "Carbon Emissions", checked: false, value: "CARBON_EMISSIONS_SCORE" },
  ],
  currencyData: [
    { name: "USD", checked: false },
    { name: "EUR", checked: false }
  ],

  marketBoxData: [
    { name: "Global Equity Large Cap", checked: false },
    { name: "Global Emerging Markets Equity", checked: false },
    { name: "US Equity Large Cap Blend", checked: false },
    { name: "Europe Equity Large Cap", checked: false },
    { name: "ESG Funds UK", checked: false },
    { name: "ESG Funds Europe", checked: false }
  ],
  settings: [
    {
      select: false, enableEdit: false,
      headings: [
        {
          title: "FUND SETTINGS",
          sideOption: [{
            optName: "Assets Class", select: false,
            value: [{ name: "Equity", checked: true },
            { name: "Multi-asset", checked: false },
            { name: "Fixed Income", checked: false }],
          },
          {
            optName: "Market Universe", select: false,
            value: [{ name: "Global Equity Large Cap", checked: false, isNew: false },
            { name: "Global Emerging Markets Equity", checked: false, isNew: false },
            { name: "US Equity Large Cap Blend", checked: false, isNew: false },
            { name: "Europe Equity Large Cap", checked: false, isNew: false },
            { name: "ESG Funds UK", checked: false, isNew: false },
            { name: "ESG Funds Europe", checked: false, isNew: false },
            { name: "ENERGY", checked: false, isNew: true },
            { name: "OCEANIA", checked: false, isNew: true },
            { name: "MATERIALS", checked: false, isNew: true },
            { name: "HEALTH CARE", checked: false, isNew: true },
            { name: "EASTERN ASIA", checked: false, isNew: true },
            { name: "SOUTH AMERICA", checked: false, isNew: true },
            { name: "EASTERN EUROPE", checked: false, isNew: true },
            { name: "WESTERN EUROPE", checked: false, isNew: true },
            { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
            { name: "EMERGINGMARKETS", checked: false, isNew: true },
            { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
            { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
            { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
            { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
            { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
            { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
            { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
            { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
            { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
            { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
            { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
            { name: "Other", checked: false, isNew: true },
            ]

          },
          {
            optName: "Geography of Sale", select: false,
          },
          {
            optName: "Display Currency", select: false, value: [{ name: "USD(coming soon)", checked: false },
            { name: "EUR", checked: false }, { name: "GBP(coming soon)", checked: false }]
          }, {
            optName: "ESG", select: false, data: [{
              heading: "Included",
              value: [{ name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
              { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
              // { name: "Pollution Prevention ", checked: false },
              { name: "Social Impact Companies ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
              { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" },]
            },
            {
              name: "Excluded", value: [{ name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
              { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
              { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
              { name: "Human Rights Norms Violation ", checked: false, value: "HR_COMPLIANCE" },
              { name: "Adult Entertainment ", checked: false, value: "AE_TIE" },
              { name: "Alcohol ", checked: false, value: "ALC_TIE" },
              { name: "Gambling", checked: false, value: "GAM_TIE" },
              { name: "Genetic Engineering - GMO ", checked: false, value: "GMO_TIE" },
              { name: "Nuclear Power ", checked: false, value: "NUC_TIE" },
              { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
              { name: "Tobacco ", checked: false, value: "TOB_TIE" },
              { name: "Controversial Weapons ", checked: false, value: "CWEAP_TIE" },
              { name: "Weapons", checked: false, value: "WEAP_GTIE" },
              { name: "Fossil Fuel Reserves", checked: false, value: "FOSSIL_FUEL_RESERVES" },
              { name: "No Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
              { name: "Carbon Emissions", checked: false, value: "CARBON_EMISSIONS_SCORE" },
              ]
            }
            ]
          }]
        },
        {
          title: "MY FUNDS", sideOption: [{ optName: "Funds", select: false },
          { optName: "Rebates", select: false }]
        },
      ]
    },
    {
      settingName: "Global", select: false, enableEdit: false,
      headings: [
        {
          title: "FUND SETTINGS",
          sideOption: [{
            optName: "Assets Class", select: false,
            value: [{ name: "Equity", checked: true },
            { name: "Multi-asset", checked: false },
            { name: "Fixed Income", checked: false }],
          },
          {
            optName: "Market Universe", select: false,
            value: [{ name: "Global Equity Large Cap", checked: false, isNew: false },
            { name: "Global Emerging Markets Equity", checked: false, isNew: false },
            { name: "US Equity Large Cap Blend", checked: false, isNew: false },
            { name: "Europe Equity Large Cap", checked: false, isNew: false },
            { name: "ESG Funds UK", checked: false, isNew: false },
            { name: "ESG Funds Europe", checked: false, isNew: false },
            { name: "ENERGY", checked: false, isNew: true },
            { name: "OCEANIA", checked: false, isNew: true },
            { name: "MATERIALS", checked: false, isNew: true },
            { name: "HEALTH CARE", checked: false, isNew: true },
            { name: "EASTERN ASIA", checked: false, isNew: true },
            { name: "SOUTH AMERICA", checked: false, isNew: true },
            { name: "EASTERN EUROPE", checked: false, isNew: true },
            { name: "WESTERN EUROPE", checked: false, isNew: true },
            { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
            { name: "EMERGINGMARKETS", checked: false, isNew: true },
            { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
            { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
            { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
            { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
            { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
            { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
            { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
            { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
            { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
            { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
            { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
            { name: "Other", checked: false, isNew: true },
            ]
          },
          {
            optName: "Geography of Sale", select: false,
          },
          {
            optName: "Display Currency", select: false, value: [{ name: "USD(coming soon)", checked: false },
            { name: "EUR", checked: false }, { name: "GBP(coming soon)", checked: false }]
          }, {
            optName: "ESG", select: false, data: [{
              name: "Included",
              value: [{ name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
              { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
              // { name: "Pollution Prevention ", checked: false },
              { name: "Social Impact Companies ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
              { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" },]
            },
            {
              name: "Excluded", value: [{ name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
              { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
              { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
              { name: "Human Rights Norms Violation ", checked: false, value: "HR_COMPLIANCE" },
              { name: "Adult Entertainment ", checked: false, value: "AE_TIE" },
              { name: "Alcohol ", checked: false, value: "ALC_TIE" },
              { name: "Gambling", checked: false, value: "GAM_TIE" },
              { name: "Genetic Engineering - GMO ", checked: false, value: "GMO_TIE" },
              { name: "Nuclear Power ", checked: false, value: "NUC_TIE" },
              { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
              { name: "Tobacco ", checked: false, value: "TOB_TIE" },
              { name: "Controversial Weapons ", checked: false, value: "CWEAP_TIE" },
              { name: "Weapons", checked: false, value: "WEAP_GTIE" },
              { name: "Fossil Fuel Reserves", checked: false, value: "FOSSIL_FUEL_RESERVES" },
              { name: "No Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
              { name: "Carbon Emissions", checked: false, value: "CARBON_EMISSIONS_SCORE" },
              ]
            }
            ]
          }]
        },
        {
          title: "MY FUNDS", sideOption: [{ optName: "Funds", select: false },
          { optName: "Rebates", select: false }]
        },
      ]
    },
    {
      settingName: "Emerging", select: false, enableEdit: false,
      headings: [
        {
          title: "FUND SETTINGS",
          sideOption: [{
            optName: "Assets Class", select: false,
            value: [{ name: "Equity", checked: true },
            { name: "Multi-asset", checked: false },
            { name: "Fixed Income", checked: false }],
          },
          {
            optName: "Market Universe", select: false,
            value: [{ name: "Global Equity Large Cap", checked: false, isNew: false },
            { name: "Global Emerging Markets Equity", checked: false, isNew: false },
            { name: "US Equity Large Cap Blend", checked: false, isNew: false },
            { name: "Europe Equity Large Cap", checked: false, isNew: false },
            { name: "ESG Funds UK", checked: false, isNew: false },
            { name: "ESG Funds Europe", checked: false, isNew: false },
            { name: "ENERGY", checked: false, isNew: true },
            { name: "OCEANIA", checked: false, isNew: true },
            { name: "MATERIALS", checked: false, isNew: true },
            { name: "HEALTH CARE", checked: false, isNew: true },
            { name: "EASTERN ASIA", checked: false, isNew: true },
            { name: "SOUTH AMERICA", checked: false, isNew: true },
            { name: "EASTERN EUROPE", checked: false, isNew: true },
            { name: "WESTERN EUROPE", checked: false, isNew: true },
            { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
            { name: "EMERGINGMARKETS", checked: false, isNew: true },
            { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
            { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
            { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
            { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
            { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
            { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
            { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
            { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
            { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
            { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
            { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
            { name: "Other", checked: false, isNew: true },
            ]
          },
          {
            optName: "Geography of Sale", select: false,
          },
          {
            optName: "Display Currency", select: false, value: [{ name: "USD(coming soon)", checked: false },
            { name: "EUR", checked: false }, { name: "GBP(coming soon)", checked: false }]
          }, {
            optName: "ESG", select: false, data: [{
              heading: "Included",
              value: [{ name: "Alternative Energy ", checked: false },
              { name: "Energy Efficient ", checked: false },
              // { name: "Pollution Prevention ", checked: true },
              { name: "Social Impact Companies ", checked: false },
              { name: "Board Independence ", checked: false },]
            },
            {
              name: "Excluded", value: [{ name: "Severe Labor Controversies ", checked: false },
              { name: "Severe Human Rights and Community Controversies ", checked: false },
              { name: "UN Global Compact Compliance Violation ", checked: false },
              { name: "Human Rights Norms Violation ", checked: true },
              { name: "Adult Entertainment ", checked: false },
              { name: "Alcohol ", checked: false },
              { name: "Gambling ", checked: false },
              { name: "Genetic Engineering - GMO ", checked: false },
              { name: "Nuclear Power ", checked: false },
              { name: "Predatory Lending ", checked: false },
              { name: "Tobacco ", checked: false },
              { name: "Controversial Weapons ", checked: false },
              { name: "Weapons", checked: false },
              { name: "Fossil Fuel Reserves", checked: false },
              { name: "No Female Directors ", checked: false },
              { name: "Carbon Emissions", checked: false },
              ]
            }
            ]
          }]
        },
        {
          title: "MY FUNDS", sideOption: [{ optName: "Funds", select: false },
          { optName: "Rebates", select: false }]
        },
      ]
    }
  ],
  preferenceData: [
    {
      title: "FUND SETTINGS",
      sideOption: [{
        optName: "Assets Class", select: false,
        value: [{ name: "Equity", checked: true },
        { name: "Fixed Income", italic: "(Coming Soon)", checked: false },
        { name: "Multi-asset", italic: "(Coming Soon)", checked: false }
        ],
      },
      {
        optName: "Market Universe", select: false,
        value: [
          //   { name: "Global Equity Large Cap", checked: false, isNew:false },
          // { name: "Global Emerging Markets Equity", checked: false, isNew:false },
          // { name: "US Equity Large Cap Blend", checked: false, isNew:false },
          // { name: "Europe Equity Large Cap", checked: false, isNew:false },
          // { name: "ESG Funds UK", checked: false, isNew:false },
          // { name: "ESG Funds Europe", checked: false, isNew:false },
          { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
          { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
          { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
          { name: "EASTERN ASIA", checked: false, isNew: true },
          { name: "EASTERN EUROPE", checked: false, isNew: true },
          { name: "EMERGINGMARKETS", checked: false, isNew: true },
          { name: "ENERGY", checked: false, isNew: true },
          { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
          { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
          { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
          { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
          { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
          { name: "HEALTH CARE", checked: false, isNew: true },
          { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
          { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
          { name: "MATERIALS", checked: false, isNew: true },
          { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
          { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
          { name: "OCEANIA", checked: false, isNew: true },
          { name: "Other", checked: false, isNew: true },
          { name: "SOUTH AMERICA", checked: false, isNew: true },
          { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
          { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
          { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
          { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
          { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
          { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
          { name: "WESTERN EUROPE", checked: false, isNew: true },
        ]
      },
      {
        optName: "Geography of Sale", select: false,
      },
      {
        optName: "Display Currency", select: false, value: [{ name: "USD(coming soon)", checked: false },
        { name: "EUR", checked: true }, { name: "GBP(coming soon)", checked: false }]
      }, {
        optName: "ESG", select: false, data: [{
          heading: "Included",
          value: [{ name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
          { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
          // { name: "Pollution Prevention ", checked: false },
          { name: "Social Impact Companies ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
          { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" }]
        },
        {
          name: "Excluded", value: [{ name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
          { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
          { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
          { name: "Human Rights Norms Violation ", checked: false, value: "HR_COMPLIANCE" },
          { name: "Adult Entertainment ", checked: false, value: "AE_TIE" },
          { name: "Alcohol ", checked: false, value: "ALC_TIE" },
          { name: "Gambling", checked: false, value: "GAM_TIE" },
          { name: "Genetic Engineering - GMO ", checked: false, value: "GMO_TIE" },
          { name: "Nuclear Power ", checked: false, value: "NUC_TIE" },
          { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
          { name: "Tobacco ", checked: false, value: "TOB_TIE" },
          { name: "Controversial Weapons ", checked: false, value: "CWEAP_TIE" },
          { name: "Weapons", checked: false, value: "WEAP_GTIE" },
          { name: "Fossil Fuel Reserves", checked: false, value: "FOSSIL_FUEL_RESERVES" },
          { name: "No Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
          { name: "Carbon Emissions", checked: false, value: "CARBON_EMISSIONS_SCORE" },
          ]
        }
        ]
      }]
    },
    {
      title: "MY FUNDS", sideOption: [{ optName: "Funds", select: false },
      { optName: "Rebates", select: false }]
    },
  ],
  geographySale: [
    { name: "Asia", checked: false, isExpend: false, data: [{ name: "Japan", checked: false }, { name: "Hong Kong", checked: false }, { name: "Singapore", checked: false }] },
    { name: "Europe", checked: false, },
    { name: "EMEA", checked: false, isExpend: false, data: [{ name: "Japan", checked: false }, { name: "Hong Kong", checked: false }, { name: "Singapore", checked: false }] },
    { name: "North America", checked: false, },
    { name: "Emerging Markets", checked: false, isExpend: false, data: [{ name: "Japan", checked: false }, { name: "Hong Kong", checked: false }, { name: "Singapore", checked: false }] },
  ]
}
