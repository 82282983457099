import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
 import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
//  import {MatTableDataSource} from '@angular/material/table';
 import {MatTableModule} from '@angular/material/table';
//import { HomeComponent } from 'src/pages/home/home.component';
//import { LoginComponent } from 'src/pages/login/login.component';
//import { WinComponent } from 'src/pages/win/win.component';
// import { ResetComponent } from 'src/pages/reset/reset.component';
//import { registerComponent } from 'src/pages/register/register.component';
//import { ForgetPasswordComponent } from 'src/pages/forget-password/forget-password.component';
 import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 import { HttpClientModule } from '@angular/common/http';
// import { HeaderComponent } from 'src/components/header/header.component';
// import { FooterComponent } from 'src/components/footer/footer.component';
//import { VerificationCodeComponent } from 'src/pages/verification-code/verification-code.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
// import { parityRecordComponent } from 'src/pages/parity-record/parity-record.component';
// import { myParityRecordComponent } from 'src/pages/my-parity-record/my-parity-record.component';
  import { CommonModule } from '@angular/common';
// import { orderListComponent } from 'src/pages/order-List/order-List.component';
// import { promotionComponent } from 'src/pages/promotion/promotion.component';
// import { rechargeComponent } from 'src/pages/recharge/recharge.component';
//import { withdrawalComponent } from 'src/pages/withdrawal/withdrawal.component';
//import { transactionListComponent } from 'src/pages/transactionList/transactionList.component';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { HomeComponent } from './components/home/home.component';
 import { LoginComponent } from './components/login/login.component';
import { registerComponent } from './components/register/register.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { adminLoginComponent } from './admin/starter/starter/admin-Components/admin-login/admin-login.component';
// import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
// import { HomeComponent } from './components/home/home.component';

function countdownConfigFactory(): any {
  return { format: `mm:ss` };
}

@NgModule({
  declarations: [
    AppComponent,
     HomeComponent,
     LoginComponent,
    //WinComponent,
    //parityRecordComponent,
  //  myParityRecordComponent,
    // ResetComponent,   
     registerComponent,
     ForgetPasswordComponent,
      HeaderComponent,
      FooterComponent,
    //VerificationCodeComponent,
   // orderListComponent,
   // promotionComponent,
  //  rechargeComponent,
    //withdrawalComponent,
    //transactionListComponent
    adminLoginComponent
  ],
  imports: [
     FormsModule,
     ReactiveFormsModule ,
    BrowserModule,
    AppRoutingModule,
     MatTableModule,
     MatPaginatorModule,
     HttpClientModule,
     MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
     NoopAnimationsModule,
     CommonModule,
     CountdownModule
  ],
  providers: [{provide: CountdownGlobalConfig, useFactory: countdownConfigFactory}],
// providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
