import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuardService {
  constructor(private router: Router,) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let localData = sessionStorage.getItem('login')    
    let AdminlocalData = sessionStorage.getItem('admin_login')    
    if (localData!=null && localData == '0' && (AdminlocalData == null || AdminlocalData == undefined)) {
     
      this.router.navigateByUrl('login'); 
      return false;
    }
    else if (localData!=null && localData == '1'){
      return true;
    }
     else {     
      this.router.navigateByUrl('login'); 
      return false;
    }
  }

}
