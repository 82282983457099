<footer class=" page-footer grid-container container-fluid ">
    <!-- <div class=" row " style="margin-right: 0px !important;
    margin-left: 0px !important;">
        <div class="col-4 text-transform: none; ">
            <a  aria-current="page"
                class=" theme--light primary--text" >
                <div class="col-sm" style="cursor: pointer;" (click)="goToModule('home')"
                    [ngStyle]="{'color': activeTab == 'home' ? '#0288d1' : 'rgb(47 47 47)' }">
                    <i class=" material-icons">home</i>
                    <p>Home </p>
                </div>
            </a>
        </div>
        
        <div class="col-4">
            <a  aria-current="page"
                class="theme--light primary--text" >
                <div class="col-sm" style="cursor: pointer;" (click)="goToModule('win')"
                    [ngStyle]="{'color': activeTab == 'win' ? '#0288d1' : 'rgb(47 47 47)' }">
                    <i class=" material-icons ">military_tech</i>
                    <p>Period  </p>
                </div>
            </a>
        </div>
        
        <div class="col-4">
            <a  aria-current="page"
                class=" theme--light primary--text" >
                <div class="col-sm" style="cursor: pointer;" (click)="goToModule('mine')"
                    [ngStyle]="{'color': activeTab == 'mine' ? '#0288d1' : 'rgb(47 47 47)' }">
                    <i class=" material-icons">portrait</i>
                    <p> Mine </p>
                </div>
            </a>
        </div>
    </div> -->
    <div class="appBottomMenu">
        <div class="item "  [ngClass]=" activeTab == 'home' ? 'active' : ''"> <a (click)="goToModule('home')">
                <p>  <i class="material-icons">home</i> <span>Home</span> </p>
            </a> </div>
        <div class="item " [ngClass]=" activeTab == 'search' ? 'active' : ''"> <a (click)="goToModule('search')" >
                <p> <i class="material-icons">search</i> <span>Search</span> </p>
            </a> </div>
        <div class="item " [ngClass]=" activeTab == 'win' ? 'active' : ''"> <a (click)="goToModule('win')" >
                <p> <i class="material-icons ">military_tech</i><span>Win</span> </p>
            </a> </div>
    
        <div class="item" [ngClass]=" activeTab == 'mine' ? 'active' : ''"> <a (click)="goToModule('mine')" class="icon toggleSidebar" >
                <p> <i class="material-icons">portrait</i> <span>My </span> </p>
            </a> </div>
    </div>
</footer>





<!-- <div class="container-fluid fixed-bottom" style="background-color:#fff;width:auto;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 -3px 20px 0 rgb(0 0 0 / 19%);">
    

<div class="navRow row justify-content-around align-items-center">
    <a href="#/" aria-current="page" class="v-btn--active v-btn v-btn--flat v-btn--router theme--light primary--text" value="home">
        <div class="col-3 v-btn__content" (click)="goToModule('home')" style="color:rgba(0,0,0,.54);margin: 14px;"

        [ngStyle]="{'color': activeTab == 'home' ? '#0288d1' : 'rgba(0,0,0,.54)' }">

        <i aria-hidden="true" class="v-icon material-icons theme--light" style="margin-bottom:9px"> sports_esports</i>
            <span>Home</span>
            
            </div>
        </a>
        <a href="#/win" class="v-btn v-btn--flat v-btn--router theme--light primary--text" value="win">
            <div class="col-3 v-btn__content" (click)="goToModule('win')" style="color:rgba(0,0,0,.54)"
            [ngStyle]="{'color': activeTab == 'win' ? '#0288d1' : 'rgba(0,0,0,.54)' }">
                <span>Period</span>
                <i aria-hidden="true" class="v-icon material-icons theme--light" style="margin-bottom:9px">military_tech</i>
            </div>
        </a>
        <a href="#/mine" class="v-btn v-btn--flat v-btn--router theme--light primary--text" value="mine">
            <div class="col-3 v-btn__content" (click)="goToModule('login')" style="color:rgba(0,0,0,.54)"
            [ngStyle]="{'color': activeTab == 'login' ? '#0288d1' : 'rgba(0,0,0,.54)' }">
                <span>Mine</span>
                <i aria-hidden="true" class="v-icon material-icons theme--light" style="margin-bottom:9px">portrait</i>
            </div>
    </a>
</div>

</div> -->