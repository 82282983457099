<div class="container-fluid headerProp" 
  style="box-shadow:0 3px 3px 0 rgb(0 0 0 / 20%), 0 7px 11px 0 rgb(0 0 0 / 20%)">
  <div class="row ">
    <div class="col-12" style="color: #fff;">
      <button class="btn  btn-icon btn-primary btn-info material-icons headerProp"
        style="line-height:unset;border-radius:50%;font-size: 25px;border: 0px;">arrow_back
        <!-- <span class="material-icons " style="line-height:unset; background-color: #0288d1;font-size: 30px;">arrow_back</span> -->
      </button>
      <span class="v-toolbar__title">Login</span>
    </div>
  </div>
</div>

<div class="container-fluid mt-3">
  <!-- style="height: 41px; background-color: #ff1744;color: #fff;padding: 10px;" -->
  <div *ngIf="errMsg.length > 0" class="alert alert-danger" style="background-color: #ff1744 !important;color:#fff">
    <div *ngFor="let error of errMsg">
      {{error}}
    </div>
  </div>

  <form class="row" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
    <div class="col-12 fontuser">
      <span floatLabel="never" class="form-element">
        <input type="text" class="form-control" [(ngModel)]="mobileNumber" autocomplete="off" matInput
          placeholder="Mobile Number" formControlName="mobile" (click)="setDefaultValue($event)">
        <i aria-hidden="true"
          [ngStyle]="{'color': formGroup.controls['mobile'].invalid && formGroup.controls['mobile'].touched ? '#ff1744' : 'gray'}"
          class="v-icon material-icons theme--light error--text control-icon">phone_android</i>
        <div class="col-12 v-messages"
          *ngIf="formGroup.controls['mobile'].invalid && formGroup.controls['mobile'].touched">
          Mobile number is required
        </div>
      </span>
    </div>
    <div class="col-12 fontuser">
      <span floatLabel="never" class="form-element">
        <input type="password" matInput class="form-control" [(ngModel)]="userPassword" autocomplete="off"
          placeholder="Password" formControlName="password">
        <i aria-hidden="true"
          [ngStyle]="{'color': !formGroup.controls['password'].valid && formGroup.controls['password'].touched ? '#ff1744' : 'gray'}"
          class="v-icon material-icons theme--light error--text control-icon">vpn_key</i>
        <div class="col-12 v-messages"
          *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched">
          Password is required
        </div>
      </span>
    </div>
    <div class="col-12">
      <div class="form-element text-center">
        <!-- [disabled]="!formGroup.valid" -->
        <button type="submit" class="btn btn-primary submit btn-info m-3">
          <div class="v-btn__content">Login
          </div>
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-3">
    <div class="col-12">
      <div class="form-element text-center">
        <!-- [disabled]="!formGroup.valid" -->
        <button type="button" class="btn register-btn" (click)="goToModule('register/')">
          <div class="v-btn__content2">Register
          </div>
        </button>
        <button type="button" class="btn register-btn" (click)="goToModule('resetPassword')">
          <div class="v-btn__content2">Forgot Password?
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="loading">
  <app-loader></app-loader>
  </div> -->