import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
// import { CommonService } from "src/services/CommonServices/common.service";
// import { MnFullpageService } from "ngx-fullpage";
// import { VerfyAuthCodeService } from "src/services/AuthServices/verfy-auth-code.service";
// import { ForgetPasswordService } from "src/services/AuthServices/forget-password.service";
// import { CookieService } from "ngx-cookie-service";
// import { LoginService } from "src/services/AuthServices/login.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  // @ViewChild("verfication") verfication;
  // @ViewChild("loginModal") loginModal;
  // authCode: any;
  // isPressed = false;
  // mySubscription: any;
  public apiDataModelStatic = [
    {
      title : 'Grow Castle',
      description  : 'It is a defense game to protect the castle from enemy attack. If growth can be placed in the castle tower and the hero on each floor.',
      imagepath : 'https://img.colorwiz.in/media/unnamed_5RHSk17.webp',
      cardColor : '#0097a7'
    },
    {
      title : 'Epic Battle Simulator 2',
      description  : 'Form your strategies, choose your troops and place them wisely on the battlefield! Play against levels, custom and real-time multiplayer!',
      imagepath : 'https://img.colorwiz.in/media/unnamed_ncPeQ7M.webp',
      cardColor : '#9c27b0'
    },
    {
      title : 'Art of War: Legions',
      description  : 'You will be the commander who leads legions of tiny armies. Accept the challenges of various levels and don’t forget to get extra rewards from bounty tasks! It’s your army, you in charge.',
      imagepath : 'https://img.colorwiz.in/media/unnamed_9dPETel.webp',
      cardColor : '#039be5'
    },
    {
      title : 'Find The Differences - The Detective',
      description  : 'Detective needs you to solve some difficult cases. You’ll be drawn into the plot of multiple investigations, each with its own unique fugitive to catch, challenges, and surprises.',
      imagepath : 'https://img.colorwiz.in/media/unnamed_os8nFrm.webp',
      cardColor : '#2e7d32'
    }
  ]
  constructor(
    public http: HttpClient,
    // public cookieService: CookieService,
    // public userLoginService: LoginService,
    // public verifyService: VerfyAuthCodeService,
    // public forgetPass: ForgetPasswordService,
    public router: Router,
    // public common: CommonService,
   // public fullpageService: MnFullpageService
  ) {
    
  }

  ngOnInit() {
  
  }

}
