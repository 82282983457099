<div class="container-fluid fixed-bottom" style="background-color:#fff;width:auto;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 -3px 20px 0 rgb(0 0 0 / 19%);">
     <!-- <div class="row justify-content-between">
        <div class="col-3 box1">box-1</div>
        <div class="col-3 box2">box-2</div>
        <div class="col-3 box3">box-3</div>
      </div> -->

<div class="navRow row justify-content-around align-items-center">
    <!-- <a href="#/" aria-current="page" class="v-btn--active v-btn v-btn--flat v-btn--router theme--light primary--text" value="home"> -->
        <div class="col-3 v-btn__content" (click)="goToModule('home')" style="color:rgba(0,0,0,.54);margin: 14px;"
        [ngStyle]="{'color': activeTab == 'home' ? '#0288d1' : 'rgba(0,0,0,.54)' }">
            <span>Home</span>
            <i aria-hidden="true" class="v-icon material-icons theme--light" style="margin-bottom:9px">
                sports_esports</i>
            </div>
        <!-- </a> -->
        <!-- <a href="#/win" class="v-btn v-btn--flat v-btn--router theme--light primary--text" value="win"> -->
            <div class="col-3 v-btn__content" (click)="goToModule('win')" style="color:rgba(0,0,0,.54)"
            [ngStyle]="{'color': activeTab == 'win' ? '#0288d1' : 'rgba(0,0,0,.54)' }">
                <span>Period</span>
                <i aria-hidden="true" class="v-icon material-icons theme--light" style="margin-bottom:9px">military_tech</i>
            </div>
        <!-- </a> -->
        <!-- <a href="#/mine" class="v-btn v-btn--flat v-btn--router theme--light primary--text" value="mine"> -->
            <div class="col-3 v-btn__content" (click)="goToModule('login')" style="color:rgba(0,0,0,.54)"
            [ngStyle]="{'color': activeTab == 'login' ? '#0288d1' : 'rgba(0,0,0,.54)' }">
                <span>Mine</span>
                <i aria-hidden="true" class="v-icon material-icons theme--light" style="margin-bottom:9px">portrait</i>
            </div>
    <!-- </a> -->
</div>

</div>