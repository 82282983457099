import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgetPasswordService } from 'src/services/AuthServices/forget-password.service';
// import { MnFullpageService } from 'ngx-fullpage';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from "../service/login.service"
// const httpOptions = {
//   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class registerComponent implements OnInit {
  mobileNumber='';
  verificationCode='';
  userPassword='';
  recomCode='';
  errorsMsg='';
  successMsg='';
  showMsg = false;
  formGroup= new FormGroup({
    mobile : new FormControl('',Validators.required) ,
    verCode : new FormControl('',Validators.required) ,
    password : new FormControl('',Validators.required) ,
    recomCode : new FormControl('',Validators.required) ,
  });
  constructor(private route:ActivatedRoute, private Login:LoginService,  private router:Router) { }

  ngOnInit(): void {
    this.recomCode = (this.route.snapshot.params['promoCode'] == null || this.route.snapshot.params['promoCode'] == undefined || this.route.snapshot.params['promoCode'] == '' ) ? "MU95EQD3" : this.route.snapshot.params['promoCode'] ;
  }

  setDefaultValue(val: any){
    
    if(val.target.classList[1] == 'ng-untouched'){
      this.mobileNumber = '+91';
    }
  }

  onSubmit(form:any){
   let json={
    "recommendationCode": this.recomCode,
    "mobile": form.mobile,
    "password": form.password,
    "verificationCode" : form.verCode
   }
   this.Login.registerUser(json).subscribe(data=>{
  },
  error=>{
    if(error['status']==200 ){
      if(error.error.error!='User Already Exists With This Mobile') {
      this.errorsMsg=''; 
      $('#successModalpopup').modal('show');
      } else {

      }
    }
    else if(error['status']==400 )
    {
      this.errorsMsg= error.error;      
    } else if(error['status']== 0) {
      this.errorsMsg="Something Went Wrong.";
     }

  })
}

getOtp(number:any){
  this.errorsMsg='';
  number = number.replace('+','%2B');
  this.Login.getOtp(number).subscribe(data=>{
    this.successMsg = data.toString();
    this.showMsg = true;
    setTimeout(() => {
      this.showMsg = false;
    },5000)
   },
   error=>{
     if(error['status']==200){
      if(error.error.text =='Enter Mobile Number'){
        this.errorsMsg='Enter Mobile Number';        
      }  
      else if(error.error.text =='OTP sent.'){
        this.successMsg = error.error.text;
        this.showMsg = true;
        setTimeout(() => {
          this.showMsg = false;
        },5000)
      } else {      
      if(error.error.error!='User Already Exists With This Mobile') {
       this.errorsMsg='';
       }  
      }
     } 
     else if(error['status']==400 && error.error=='User Already Exists With This Mobile')
     {
       this.errorsMsg="User Already Exists With This Mobile";
     } else if(error['status']== 0) {
      this.errorsMsg="Something Went Wrong.";
     }
   })
}
goBack(){
  this.router.navigateByUrl('login');
}
}
