
 <div *ngIf="errMsg.length > 0" class="alert alert-danger" style="margin-bottom: 0px; background-color: #ff1744 !important;color:#fff" >
  <div *ngFor="let error of errMsg">
           {{error}}
         </div>
 </div>
<div class="hold-transition login-page">
  <div class="login-box">
    <!-- /.login-logo -->
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <a href="../../index2.html" class="h1"><b>Admin</b></a>
      </div>
      <div class="card-body">
        <p class="login-box-msg">Sign in to start your session</p>
  
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
          <div class="input-group mb-3">
            <input type="text" [(ngModel)]="userName" autocomplete="off" placeholder="User Name" formControlName="username" class="form-control" >
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
            <div class="col-12 v-messages" >
            <span  *ngIf="formGroup.controls['username'].invalid && formGroup.controls['username'].touched">  User Name is required </span>
          </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" [(ngModel)]="userPassword" autocomplete="off" placeholder="Password" formControlName="password">
            <div class="input-group-append">
              <div class="input-group-text">
                <span  class="fas fa-lock"></span>
              </div>
            </div>
            <div class="col-12 v-messages" >
             <span *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched"> Password is required </span>
          </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember">
                <label for="remember">
                  Remember Me
                </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <!-- (click)="goTO('admin/dash')" -->
              <button type="submit" class="btn btn-primary btn-block" >Sign In</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
  
        <!-- <div class="social-auth-links text-center mt-2 mb-3">
          <a href="#" class="btn btn-block btn-primary">
            <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
          </a>
          <a href="#" class="btn btn-block btn-danger">
            <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
          </a>
        </div> -->
        <!-- /.social-auth-links -->
  
        <p class="mb-1">
          <a (click)="goTO('admin/resetPassword')" class="text-center">I forgot my password</a>
        </p>
        <!-- <p class="mb-0">
          <a (click)="goTO('admin/register')" class="text-center">Register a new membership</a>
        </p> -->
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
</div>