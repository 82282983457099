import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
// import { CommonService } from "src/services/CommonServices/common.service";
//import { encode } from "string-encode-decode";
//import { UUID } from "angular2-uuid";
//import { CookieService } from "ngx-cookie-service";
//import { LoginService } from "src/services/AuthServices/login.service";
//import { decode } from "string-encode-decode";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from "../service/login.service"
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  mobileNumber='';
  userPassword='';
  //errors='';
  formGroup= new FormGroup({
    mobile : new FormControl('',Validators.required) ,
    password : new FormControl('',Validators.required) 
  });
  errMsg: any[] = [];
  constructor(private route:ActivatedRoute, private LoginService : LoginService, private router:Router) { }

  ngOnInit() { 
    // localStorage.removeItem('Token');
    // localStorage.removeItem('user_Id')
    // localStorage.removeItem('login');
    // localStorage.removeItem('Token');
    // localStorage.removeItem('admin_user_Id')
    // localStorage.removeItem('admin_login');  
    sessionStorage.removeItem("Token");
    sessionStorage.removeItem("user_Id");
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("admin_user_Id");
    sessionStorage.removeItem("admin_login");
  }  
  setDefaultValue(val:any){
    
    if(val.target.classList[1] == 'ng-untouched'){
      this.mobileNumber = '+91';
    }
  }
  goToModule(url:any){
    this.router.navigateByUrl(url);
  }
  checkValidation(json:any) {         
    if(json.mobile == '' || json.mobile == null){
     this.errMsg.push('Mobile Number is required.')
    }
    if(json.password == 0 || json.password == null){
      this.errMsg.push('Password is required.')
    }
    if(this.errMsg.length > 0){
      return true;
    } else {
      return false;
    }
  }
  onSubmit(data:any){
    this.errMsg = [];
    //this.errors='';
     let json;
    json={
    "grant_Type": "password",
    "mobile": data.mobile,
    "password": data.password,
  }
  //  if(this.checkValidation(json)) {
  //    return false
  //  }
  this.LoginService.loginUser(json).subscribe((data:any)=>{  
  console.log("data is",data);
  // this.errors='';
  this.errMsg = [];
  // localStorage.setItem('Token',data.access_token);
  // localStorage.setItem('user_Id',data.user_Id)
  // localStorage.setItem('login', '1');
  sessionStorage.setItem("Token",data.access_token);
  sessionStorage.setItem("user_Id",data.user_Id);
  sessionStorage.setItem("login", '1');
  // this.router.navigate(['/home']);
  this.router.navigateByUrl('view/mine'); 
 },
 error=>{
   console.log('error',error)
   if(error['status']==400){
     //this.errors=error.errors
     this.errMsg.push(error.error);
   } else if(error['status']== 0) {
    this.errMsg.push('Something Went Wrong.');    
   }
 })
  }

}
