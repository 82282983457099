<div class="container-fluid headerProp" 
  style="box-shadow:0 3px 3px 0 rgb(0 0 0 / 20%), 0 7px 11px 0 rgb(0 0 0 / 20%)">
  <div class="row ">
    <div class="col-12" style="color: #fff;">
      <button class="btn  btn-icon btn-primary btn-info material-icons headerProp"
        style="line-height:unset;border-radius:50%;font-size: 25px;border: 0px;" (click)="goBack()">arrow_back
        <!-- <span class="material-icons " style="line-height:unset; background-color: #0288d1;font-size: 30px;">arrow_back</span> -->
      </button>
      <span class="v-toolbar__title">Register</span>
    </div>
  </div>
</div>

<div class="container-fluid mt-3">
  <form class="row" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
    <div *ngIf="errorsMsg!=''" class="row form-row">
      {{errorsMsg}}
    </div>
    <div *ngIf="showMsg" class="row" style="height: 41px; background-color: green;color: #fff;padding: 10px;">
      {{this.successMsg}}
    </div>
    <div class="col-12 fontuser">
      <span floatLabel="never" class="form-element">
        <input type="text" class="form-control" [(ngModel)]="mobileNumber" autocomplete="off" matInput
          placeholder="Mobile Number" formControlName="mobile" (click)="setDefaultValue($event)">
        <i aria-hidden="true"
          [ngStyle]="{'color': formGroup.controls['mobile'].invalid && formGroup.controls['mobile'].touched ? '#ff1744' : 'gray'}"
          class="v-icon material-icons theme--light error--text control-icon">phone_android</i>
        <div class="col-12 v-messages"
          *ngIf="formGroup.controls['mobile'].invalid && formGroup.controls['mobile'].touched">
          Mobile number is required
        </div>
      </span>
    </div>
    <div class="row" style="margin-left: 0;">
      <div class="col-8 fontuser">
        <span floatLabel="never" class="form-element">
          <input type="text" matInput class="form-control" [(ngModel)]="verificationCode" autocomplete="off"
            placeholder="Verification Code" formControlName="verCode" required>
          <i aria-hidden="true"
            [ngStyle]="{'color': !formGroup.controls['verCode'].valid && formGroup.controls['verCode'].touched ? '#ff1744' : 'gray'}"
            class=" v-icon material-icons theme--light error--text control-icon">sms</i>
          <div class="col-12 v-messages"
            *ngIf="!formGroup.controls['verCode'].valid && formGroup.controls['verCode'].touched">
            Verification Code is required
          </div>
        </span>
      </div>
      <div class="col-4 fontuser">
        <button type="button" class="btn register-btn" (click)="getOtp(mobileNumber)">
          OTP
        </button>
      </div>
    </div>
    <div class="col-12 fontuser">
      <span floatLabel="never" class="form-element">
        <input type="password" matInput class="form-control" [(ngModel)]="userPassword" autocomplete="off"
          placeholder="Password" formControlName="password">
        <i aria-hidden="true"
          [ngStyle]="{'color': !formGroup.controls['password'].valid && formGroup.controls['password'].touched ? '#ff1744' : 'gray'}"
          class="v-icon material-icons theme--light error--text control-icon">vpn_key</i>
        <div class="col-12 v-messages"
          *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched">
          Password is required
        </div>
      </span>
    </div>
    <div class="col-12 fontuser">
      <span floatLabel="never" class="form-element">
        <input type="text" matInput class="form-control" [(ngModel)]="recomCode" autocomplete="off"
          placeholder="Recommendation Code" formControlName="recomCode">
        <i aria-hidden="true"
          [ngStyle]="{'color': !formGroup.controls['recomCode'].valid && formGroup.controls['recomCode'].touched ? '#ff1744' : 'gray'}"
          class="v-icon material-icons theme--light error--text control-icon">redeem</i>
        <div class="col-12 v-messages"
          *ngIf="!formGroup.controls['recomCode'].valid && formGroup.controls['recomCode'].touched">
          Recommendation Code is required
        </div>
      </span>
    </div>
    <div class="col-12">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked>
        <label class="form-check-label" for="flexCheckDefault"
          style="font-size: 18px;color:rgba(0,0,0,.54);font-weight: 500;">
          I agree
        </label>
        <label class="form-check-label primary--text" style="font-size: 14px;
        font-weight: 550;
        letter-spacing: .4px;" data-toggle="modal" data-target="#exampleModal4">
          PRIVACY POLICY
        </label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-element text-center">
        <!-- [disabled]="!formGroup.valid" -->
        <button type="submit" class="btn btn-primary submit btn-info m-3">
          <div class="v-btn__content">Register
          </div>
        </button>
      </div>
    </div>
  </form>


</div>

<div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel4"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-xl" style="max-width: 90vw;" role="document">
    <div class="modal-content">
      <div class="modal-header grey lighten-2">
        <div class="modal-title headline " id="exampleModalLabel">Privacy Policy</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="height: 74vh;
        overflow: auto;">
        <div class="content">
          <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
            information when You use the Service and tells You about Your privacy rights and how the law protects
            You.</p>
          <h2>Interpretation and Definitions</h2>
          <h4>Interpretation</h4>
          <p>The words of which the initial letter is capitalized have meanings defined under the following
            conditions.</p>
          <p>The following definitions shall have the same meaning regardless of whether they appear in singular or
            in
            plural.
          </p>
          <h4>Definitions</h4>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                legal
                entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
            <li>
              <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                Agreement)
                refers to colorwiz.in.</p>
            </li>
            <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
              control with a
              party, where "control" means ownership of 50% or more of the shares, equity interest or other
              securities
              entitled to vote for election of directors or other managing authority.
            </li>
            <li><strong>Account</strong> means a unique account created for You to access our Service or parts of
              our
              Service.
            </li>
            <li><strong>Website</strong> refers to colorwiz.in, accessible from
              https://colorwiz.in
            </li>
            <li><strong>Service</strong> refers to the Website.</li>
            <li><strong>Country</strong> refers to: Uttar Pradesh, India</li>
            <li>
              <p><strong>Service Provider</strong> means any natural or legal person who processes the data on
                behalf of
                the Company. It refers to third-party companies or individuals employed by the Company to facilitate
                the
                Service, to provide the Service on behalf of the Company, to perform services related to the Service
                or
                to assist the Company in analyzing how the Service is used.</p>
            </li>
            <li><strong>Third-party Social Media Service</strong> refers to any website or any social network
              website
              through which a User can log in or create an account to use the Service.
            </li>
            <li>
              <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                individual.</p>
            </li>
            <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any
              other device
              by a website, containing the details of Your browsing history on that website among its many uses.
            </li>
            <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
              or a
              digital tablet.
            </li>
            <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
              the
              Service or from the Service infrastructure itself (for example, the duration of a page visit).
            </li>
          </ul>
          <h2>Collecting and Using Your Personal Data</h2>
          <h4>Types of Data Collected</h4>
          <h5>Personal Data</h5>
          <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
            that can
            be used to contact or identify You. Personally identifiable information may include, but is not limited
            to:</p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Usage Data</li>
          </ul>
          <h5>Usage Data</h5>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
            browser
            type, browser version, the pages of our Service that You visit, the time and date of Your visit, the
            time spent
            on those pages, unique device identifiers and other diagnostic data.</p>
          <p>When You access the Service by or through a mobile device, We may collect certain information
            automatically,
            including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP
            address
            of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique
            device
            identifiers and other diagnostic data.</p>
          <p>We may also collect information that Your browser sends whenever You visit our Service or when You
            access the
            Service by or through a mobile device.</p>
          <h5>Tracking Technologies and Cookies</h5>
          <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
            information. Tracking technologies used are beacons, tags, and scripts to collect and track information
            and to
            improve and analyze Our Service.</p>
          <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
            However, if You
            do not accept Cookies, You may not be able to use some parts of our Service.</p>
          <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer
            or mobile
            device when You go offline, while Session Cookies are deleted as soon as You close your web browser.
          </p>
          <p>We use both session and persistent Cookies for the purposes set out below:</p>
          <ul>
            <li>
              <p><strong>Necessary / Essential Cookies</strong></p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies are essential to provide You with services available through the Website and
                to
                enable You to use some of its features. They help to authenticate users and prevent fraudulent use
                of
                user accounts. Without these Cookies, the services that You have asked for cannot be provided, and
                We
                only use these Cookies to provide You with those services.</p>
            </li>
            <li>
              <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
            </li>
            <li>
              <p><strong>Functionality Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                remembering your login details or language preference. The purpose of these Cookies is to provide
                You
                with a more personal experience and to avoid You having to re-enter your preferences every time You
                use
                the Website.</p>
            </li>
          </ul>
          <p>For more information about the cookies we use and your choices regarding cookies, please visit our
            Cookies
            Policy.</p>
          <h4>Use of Your Personal Data</h4>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
            </li>
            <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
              Personal
              Data You provide can give You access to different functionalities of the Service that are available to
              You
              as a registered user.
            </li>
            <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of
              the
              purchase contract for the products, items or services You have purchased or of any other contract with
              Us
              through the Service.
            </li>
            <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
              forms of
              electronic communication, such as a mobile application's push notifications regarding updates or
              informative
              communications related to the functionalities, products or contracted services, including the security
              updates, when necessary or reasonable for their implementation.
            </li>
            <li><strong>To provide You</strong> with news, special offers and general information about other goods,
              services and events which we offer that are similar to those that you have already purchased or
              enquired
              about unless You have opted not to receive such information.
            </li>
            <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
          </ul>
          <p>We may share your personal information in the following situations:</p>
          <ul>
            <li><strong>With Service Providers:</strong> We may share Your personal information with Service
              Providers to
              monitor and analyze the use of our Service, to contact You.
            </li>
            <li><strong>For Business transfers:</strong> We may share or transfer Your personal information in
              connection
              with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all
              or a
              portion of our business to another company.
            </li>
            <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case
              we will
              require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any
              other
              subsidiaries, joint venture partners or other companies that We control or that are under common
              control
              with Us.
            </li>
            <li><strong>With Business partners:</strong> We may share Your information with Our business partners to
              offer
              You certain products, services or promotions.
            </li>
            <li><strong>With other users:</strong> when You share personal information or otherwise interact in the
              public
              areas with other users, such information may be viewed by all users and may be publicly distributed
              outside.
              If You interact with other users or register through a Third-Party Social Media Service, Your contacts
              on
              the Third-Party Social Media Service may see You name, profile, pictures and description of Your
              activity.
              Similarly, other users will be able to view descriptions of Your activity, communicate with You and
              view
              Your profile.
            </li>
          </ul>
          <h4>Retention of Your Personal Data</h4>
          <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
            this
            Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
            legal
            obligations (for example, if we are required to retain your data to comply with applicable laws),
            resolve
            disputes, and enforce our legal agreements and policies.</p>
          <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
            retained for a
            shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality
            of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
          <h4>Transfer of Your Personal Data</h4>
          <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
            other
            places where the parties involved in the processing are located. It means that this information may be
            transferred to — and maintained on — computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ than those from Your
            jurisdiction.</p>
          <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
            agreement
            to
            that transfer.</p>
          <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and
            in
            accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
            organization or
            a country unless there are adequate controls in place including the security of Your data and other
            personal
            information.</p>
          <h4>Disclosure of Your Personal Data</h4>
          <h5>Business Transactions</h5>
          <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
            transferred. We
            will
            provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
            Policy.</p>
          <h5>Law enforcement</h5>
          <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to
            do so by
            law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
          <h5>Other legal requirements</h5>
          <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary
            to:</p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
          <h4>Security of Your Personal Data</h4>
          <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
            the
            Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable
            means
            to protect Your Personal Data, We cannot guarantee its absolute security.</p>
          <h2>Children's Privacy</h2>
          <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
            identifiable
            information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
            child
            has provided Us with Personal Data, please contact Us. If We become aware that We have collected
            Personal Data
            from anyone under the age of 13 without verification of parental consent, We take steps to remove that
            information from Our servers.</p>
          <p>If We need to rely on consent as a legal basis for processing Your information and Your country
            requires
            consent
            from a parent, We may require Your parent's consent before We collect and use that information.</p>
          <h2>Links to Other Websites</h2>
          <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
            party
            link,
            You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
            every
            site You visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
            any
            third
            party sites or services.</p>
          <h2>Changes to this Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the
            new
            Privacy
            Policy on this page.</p>
          <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
            effective
            and update the "Last updated" date at the top of this Privacy Policy.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
            Policy are
            effective when they are posted on this page.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, You can contact us:</p>
          <ul>
            <li>By visiting this page on our website: https://colorwiz.in/#/suggestion</li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-light" data-dismiss="modal">CLOSE</button>
      </div>
    </div>
  </div>
</div>



<div #successModalpopup class="modal fade" data-backdrop="static" data-keyboard="false" id="successModalpopup"
  tabindex="-1" role="dialog" aria-labelledby="successModalpopup" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: none;">
        <div class="modal-title headlineTransferPopup " id="exampleModalLabel">Confirm</div>
      </div>
      <div class="modal-body" style="padding: 0px !important;">
        <div class="content">
          <div class="container">
            <div class="row mt-2">
              <div class="col-12 ">
                Registration successful
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="border-top: none;">
        <button type="button" class="btn btn-outline-light primary--text" data-dismiss="modal"
          (click)="goBack()">OK</button>
      </div>
    </div>
  </div>
</div>


<!-- <app-header></app-header> -->







<!-- <app-header [loginSelected]="loginPress"></app-header> -->




<!-- <a data-target="#verficationCode" data-toggle="modal" id="openVerfication"></a>

    <app-verification-code></app-verification-code>

    <app-forget-password></app-forget-password> -->