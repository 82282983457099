


<div class="container-fluid headerProp" style="box-shadow:0 3px 3px 0 rgb(0 0 0 / 20%), 0 7px 11px 0 rgb(0 0 0 / 20%)">
    <div class="row ">
        <div class="col-12" style="color: #fff;    margin: 7px 0px 7px 0px;">
            <button class="btn  btn-icon btn-primary btn-info material-icons headerProp" style="line-height:unset;border-radius:50%;font-size: 30px;border: 0px;" (click)="goBack()">arrow_back
             <!-- <span class="material-icons " style="line-height:unset; background-color: #0288d1;font-size: 30px;">arrow_back</span> -->
            </button>
             <span class="v-toolbar__title">Reset Password</span>
        </div>
    </div>
</div>



<div class="container-fluid mt-3"> 
    <form class="row" [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
      <div *ngIf="errors!=''" class="row" style="height: 41px; background-color: #ff1744;color: #fff;padding: 10px;">
        {{errors}}
      </div>
      <div *ngIf="showMsg" class="row" style="height: 41px; background-color: green;color: #fff;padding: 10px;">
        OTP send
      </div>
  <div class="col-12 fontuser" >
      <span floatLabel="never" class="form-element">
        <input type="text" class="form-control" [(ngModel)]="mobileNumber" autocomplete="off" matInput placeholder="Mobile Number" formControlName="mobile" (click)="setDefaultValue($event)">
        <i aria-hidden="true" [ngStyle]="{'color': formGroup.controls['mobile'].invalid && formGroup.controls['mobile'].touched ? '#ff1744' : 'gray'}" class="v-icon material-icons theme--light error--text control-icon" >phone_android</i>
        <div class="col-12 v-messages" *ngIf="formGroup.controls['mobile'].invalid && formGroup.controls['mobile'].touched">
            Mobile number is required
        </div>
      </span>
    </div>
    <div class="row" style="margin-left: 0;" >
    <div class="col-8 fontuser">
        <span floatLabel="never" class="form-element">
          <input type="text" matInput class="form-control" [(ngModel)]="verificationCode" autocomplete="off" placeholder="Verification Code" formControlName="verCode" required>
          <i aria-hidden="true" [ngStyle]="{'color': !formGroup.controls['verCode'].valid && formGroup.controls['verCode'].touched ? '#ff1744' : 'gray'}" class=" v-icon material-icons theme--light error--text control-icon">sms</i>
          <div class="col-12 v-messages" *ngIf="!formGroup.controls['verCode'].valid && formGroup.controls['verCode'].touched">
            Verification Code is required
          </div>
        </span>
      </div>
      <div class="col-4 fontuser" style="padding: 0 0px;margin-left: -11px;">
        <button type="button" class="btn register-btn" (click)="getOtp(mobileNumber)">
          <div class="v-btn__content2">OTP
          </div>
      </button>
      </div>
    </div>
    <div class="col-12 fontuser">
      <span floatLabel="never" class="form-element">
        <input type="password" matInput class="form-control" [(ngModel)]="userPassword" autocomplete="off" placeholder="New Password" formControlName="password">
        <i aria-hidden="true" [ngStyle]="{'color': !formGroup.controls['password'].valid && formGroup.controls['password'].touched ? '#ff1744' : 'gray'}" class="v-icon material-icons theme--light error--text control-icon">vpn_key</i>
        <div class="col-12 v-messages" *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched">
            Password is required
        </div>
      </span>
    </div>
    <div class="col-12">
      <div class="form-element text-center" >
        <!-- [disabled]="!formGroup.valid" -->
        <button type="submit" class="btn btn-primary submit btn-info m-3" >
            <div class="v-btn__content">Continue
            </div>
        </button>
      </div>
    </div>
    </form>

   
  </div>
  
 


  <div #successModalpopup class="modal fade" data-backdrop="static" data-keyboard="false" id="successModalpopup" tabindex="-1" role="dialog" aria-labelledby="successModalpopup" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-sm"  role="document">
      <div class="modal-content"> 
             <div class="modal-header"	style="border-bottom: none;">      
          <div class="modal-title headlineTransferPopup " id="exampleModalLabel">Confirm</div>      
        </div>
        <div class="modal-body" style="padding: 0px !important;">
          <div class="content">
            <div class="container">               
        <div class="row mt-2">
          <div class="col-12 ">   
           Password changes successfully
        </div>
        </div>      
      </div>
        </div>
        </div>
        <div class="modal-footer" style="border-top: none;">       
          <button type="button" class="btn btn-outline-light primary--text"  data-dismiss="modal" (click)="goBack()">OK</button>          
        </div>      
      </div>
    </div>
  </div>



<!-- <app-header></app-header> -->







<!-- <app-header [loginSelected]="loginPress"></app-header> -->


    

    <!-- <a data-target="#verficationCode" data-toggle="modal" id="openVerfication"></a>

    <app-verification-code></app-verification-code>

    <app-forget-password></app-forget-password> -->
