import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthenticatedGuardActivePathService } from 'src/services/AuthServices/admin-authenticated-guard-active-path.service';
//import { UserDashboardComponent } from 'src/components/user-dashboard/user-dashboard.component';
//import { ForgetPasswordComponent } from 'src/pages/forget-password/forget-password.component';
//import { HomeComponent } from 'src/pages/home/home.component';
//import { LoginComponent } from 'src/pages/login/login.component';
// import { mineComponent } from 'src/pages/mine/mine.component';
// import { myParityRecordComponent } from 'src/pages/my-parity-record/my-parity-record.component';
// import { orderListComponent } from 'src/pages/order-List/order-List.component';


// import { parityRecordComponent } from 'src/pages/parity-record/parity-record.component';
// import { promotionComponent } from 'src/pages/promotion/promotion.component';
// import { rechargeComponent } from 'src/pages/recharge/recharge.component';
// import { registerComponent } from 'src/pages/register/register.component';
//import { transactionListComponent } from 'src/pages/transactionList/transactionList.component';
// import { ResetComponent } from 'src/pages/reset/reset.component';
//import { WinComponent } from 'src/pages/win/win.component';
//import { withdrawalComponent } from 'src/pages/withdrawal/withdrawal.component';
 import { AuthenticatedGuardService } from 'src/services/AuthServices/authenticated-guard.service';
 import { constants } from "src/utils/constants";
 import { AuthenticatedGuardActivePathService } from "../services/AuthServices/authenticated-guard-active-path.service";
// // import { StarterModule } from './admin/starter/starter.module';
import { adminForgotPasswordComponent } from './admin/starter/starter/admin-Components/admin-forgot-password/admin-forgot-password.component';
import { adminLoginComponent } from './admin/starter/starter/admin-Components/admin-login/admin-login.component';
import { adminRegisterComponent } from './admin/starter/starter/admin-Components/admin-register/admin-register.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { registerComponent } from './components/register/register.component';
// import { HomeComponent } from './components/home/home.component';
// import { ForgetPasswordComponent } from './main-layout/pages/forget-password/forget-password.component';

const routes: Routes = [
  {
    path: constants.paths.blank,
    pathMatch: constants.paths.full,
    redirectTo: constants.paths.login,
  },
  {
    path: constants.paths.login,
    component: LoginComponent,
    canActivate: [AuthenticatedGuardActivePathService]
  },
  {
    path: 'view',
    loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule)
  },  
   {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthenticatedGuardActivePathService]
  }, 
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticatedGuardActivePathService]
  },
  {
    path: 'resetPassword',
    component: ForgetPasswordComponent,
    canActivate: [AuthenticatedGuardActivePathService]
  },
  {
    path: 'register/:promoCode',
    component: registerComponent,
    canActivate: [AuthenticatedGuardActivePathService]
  },
  {
    path: 'admin',
    component: adminLoginComponent,
    // canActivate: [AdminAuthenticatedGuardActivePathService]
  },
  // {
  //   path: 'admin/resetPassword',
  //   component: adminForgotPasswordComponent,
  //   canActivate: [AdminAuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'admin/register',
  //   component: adminRegisterComponent,
  //   canActivate: [AdminAuthenticatedGuardActivePathService]
  // },
  {
    path: 'admin/dash',
    loadChildren: () => import('./admin/starter/starter.module').then(m => m.StarterModule)
  },
  // {
  //   path: 'win',
  //   component: WinComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'mine',
  //   component: mineComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'orderList',
  //   component: orderListComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'promotion',
  //   component: promotionComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'recharge',
  //   component: rechargeComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //    path:'withdrawal',
  //    component: withdrawalComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'transactionList',
  //   component: transactionListComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'guessRecord/:category_value',
  //   component: parityRecordComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'myGuessRecord/:category_value',
  //   component: myParityRecordComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },
  // {
  //   path: 'dash',
  //   component: VerificationCodeComponent,
  //   canActivate: [AuthenticatedGuardActivePathService]
  // },  
  
  // { path: "orderList", component: orderListComponent },
  
  // {
  //   path: "user-dashboard",
  //   component: UserDashboardComponent,
  //   canActivate: [AuthenticatedGuardService],
  // },
   {
    path: "**",
    component: LoginComponent,
    canActivate: [AuthenticatedGuardService],
  }
  // { path: "resetpassword", component: ResetComponent },
 // { path: "changePassword", component: ChangePasswordComponent },
];

@NgModule({
 // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { useHash: true }), ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
