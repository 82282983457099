import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuardActivePathService {

  constructor(private router: Router, ) { }
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let localData = sessionStorage.getItem('login');
    let AdminlocalData = sessionStorage.getItem('admin_login') 
   if(localData && localData!=null && localData == '1') {
      //this.router.navigate(["/user-dashboard"]);
      this.router.navigateByUrl('view/win');
      return false;
    } else if (AdminlocalData && AdminlocalData!=null && AdminlocalData == '1' ) {
      return false;
    }else{
      return true
    }
  }

}

// {
//   constructor(private router: Router,) { }

//   public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     let localData = localStorage.getItem('admin_login')    
//     if (localData!=null && localData == '0') {
     
//       this.router.navigateByUrl('admin'); 
//       return false;
//     }
//     else if (localData!=null && localData == '1'){
//       return true;
//     }
//      else {     
//       this.router.navigateByUrl('admin'); 
//       return false;
//     }
//   }

// }
