import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from '../../../services/utils.service'
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class adminLoginService {
 public baseUrl = new UtilsService().baseUrl;
  constructor(public http: HttpClient, public utils: UtilsService) { }

  loginUser(regData:any){  
    return this.http.post(this.baseUrl + "api/Token/auth", regData, httpOptions);  
    }
  registerUser(regData:any){  
    return this.http.post(this.baseUrl + "api/User/Create", regData, httpOptions);    
    }
    getOtp(number:any){
      return this.http.get(this.baseUrl + "api/Token/SendOtp?mobile="+number,  httpOptions);   
    }   
}
