import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
// import { CommonService } from "src/services/CommonServices/common.service";
import { ForgetPasswordService } from "src/services/AuthServices/forget-password.service";
// import { UUID } from "angular2-uuid";
// import { CookieService } from "ngx-cookie-service";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from "../service/login.service"
declare var $: any;
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  mobileNumber='';
  verificationCode='';
  userPassword='';
  formGroup= new FormGroup({
    mobile : new FormControl('',Validators.required) ,
    verCode : new FormControl('',Validators.required) ,
    password : new FormControl('',Validators.required) 
  });
  showMsg = false;
  errors='';
  constructor(
      private router:Router, private Login:LoginService
  ) {
  
  }

  
 

  ngOnInit() {
 
  }
  setDefaultValue(val:any){
    
    if(val.target.classList[1] == 'ng-untouched'){
      this.mobileNumber = '+91';
    }
  }

  getOtp(number:any){
    this.errors='';
    number = number.replace('+','%2B');
    this.Login.getOtp(number).subscribe(data=>{
      console.log('data',data)
      this.showMsg = true;
      setTimeout(() => {
        this.showMsg = false;
      },3000)
      // this.verificationCode = data.toString()
     },
     error=>{
        console.log('error',error)
       if(error['status']==200){
        if(error.error.text =='Enter Mobile Number'){
          this.errors='Enter Mobile Number';        
        } else if(error.error.text =='OTP sent.'){
          this.showMsg = true;
          setTimeout(() => {
            this.showMsg = false;
          },3000)
        } else {
        if(error.error.error!='User Already Exists With This Mobile') {
         this.errors='';
         console.log("Record(s) Created Successfully")
         }  
        }
       } 
       else if(error['status']==400 && error.error=='User Already Exists With This Mobile')
       {
         this.errors="User Already Exists With This Mobile";
         console.log("User Already Exists With This Mobile")
       } else if(error['status']== 0) {
        this.errors="Something Went Wrong.";
       }
     })
  }

  onSubmit(form:any){    
    console.log("on submit",form);
    let json={     
     "mobile": form.mobile,
     "newPassword": form.password,
     "verificationCode" : form.verCode
    }
    this.Login.resetPassword(json).subscribe(data=>{
    console.log('data',data)
   },
   error=>{
      console.log('error',error)
     if(error['status']==200 ){       
       this.errors='';
       console.log("Record(s) Created Successfully")      
       $('#successModalpopup').modal('show');
       
     }
     else if(error['status']==400 )
     {
       this.errors= error.error;      
     } else if(error['status']== 0) {
       this.errors="Something Went Wrong.";
      }
 
   })
 }
  goBack(){
    this.router.navigateByUrl('login');
  }
}
