import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public activeTab = 'mine' ;
  constructor(private router: Router ) { }

  ngOnInit(): void {
    // let localData = localStorage.getItem('login')    
    // let logged = false;
    // if (localData == '1') {     
    //   logged = true;
    // } else {
    //   logged = false;
    // }
    
  //  if(this.router.url.indexOf('home') > 0 || this.router.url.indexOf('view/home') > 0) {
  //   this.activeTab = 'home';
  //  } else if (this.router.url.indexOf('login') > 0 || this.router.url.indexOf('view/mine') > 0 ) {
  //   this.activeTab = 'mine';
  //  } else {
  //    if(logged) {
  //     this.activeTab = 'win';    
  //    }
  //  }
  }
  goToModule(tab : any){
    let localData = sessionStorage.getItem('login')    
    let logged = false;
    if (localData == '1') {     
      logged = true;
    } else {
      logged = false;
    }
 
    if(tab == 'home'){
      if(logged == true){
        this.activeTab = tab;
        this.router.navigateByUrl('view/' + tab);
      } else {
        this.activeTab = tab;
        this.router.navigateByUrl(tab);
      }
    } else if(tab == 'win') {
      if(logged == true){
        this.activeTab = tab;
        this.router.navigateByUrl('view/' + tab);
      }
    } else {
      if(logged == true){
        this.activeTab = tab;
        this.router.navigateByUrl('view/' + tab);
      } else {
        this.activeTab = tab;
        this.router.navigateByUrl('login');
      }
    }

    // if(tab=='win' || tab=='mine'){
    //   if(localStorage.getItem('Token'))
    //   {
    //     this.activeTab = tab;
    //     this.router.navigateByUrl('view/' + tab);
    //   } else {
    //     if(tab=='mine') {
    //       this.activeTab = tab;
    //     }        
    //     this.router.navigateByUrl('');
    //   }
    // }else if(tab !='win'){
    //   this.activeTab = tab;
    //   this.router.navigateByUrl(tab);
    // }
    
      
  }

   
}
